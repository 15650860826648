import { useAuthentication } from 'src/features/authentication/context';
import { ElementProps } from '../../../types/element';
import { BuildReportsRouteElement } from './BuildReportsRouteElement';

export const BuildReportsElement = ({ isSuperAdmin }: ElementProps) => {
  const { isCustomerAccessAllowed, loggedIn, customerId } = useAuthentication();

  return (
    <BuildReportsRouteElement
      loggedIn={loggedIn}
      isSuperAdmin={isSuperAdmin}
      isCustomerAccessAllowed={isCustomerAccessAllowed}
      customerId={customerId}
    />
  );
};
