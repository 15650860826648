import { Typography, Stack, Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { CustomWaterLoading } from 'src/components';

interface IReportPreviewLoadingProps {
  onCancel: () => void;
}

const ReportPreviewLoading: FunctionComponent<IReportPreviewLoadingProps> = ({ onCancel }) => (
  <Stack
    sx={{ width: '100%', minHeight: '450px' }}
    alignItems="center"
    height="100%"
    justifyContent="center"
  >
    <Stack alignItems="center">
      <CustomWaterLoading />
      <Typography textAlign="center" sx={{ marginTop: '16px' }} variant="h6">
        The report will be ready shortly. <br /> You can navigate through the app while report is
        being generated.
      </Typography>

      <Button sx={{ marginTop: '32px' }} onClick={onCancel} variant="outlined">
        Cancel
      </Button>
    </Stack>
  </Stack>
);

export default ReportPreviewLoading;
