import { FormControl, MenuItem, Select, SelectProps, useTheme } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { api } from 'src/api.client';
import { useAuthentication } from 'src/features/authentication/context';
import { useAssetPropertyData } from 'src/features/reports/context/assetPropertyContext';
import { getAvailableAssetProperties } from './utils/getAvailableAssetProperties';
import Label from 'src/components/minimals/form/Label';

const AssetPropertySelect: FunctionComponent<
  SelectProps & {
    fetchAssetProperties: boolean;
  }
> = ({ value, fetchAssetProperties, ...props }) => {
  const [tags, setTags] = useState<{ value: string; label: string; available: boolean }[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const assetPropertyData = useAssetPropertyData();
  const theme = useTheme();
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();

  useEffect(() => {
    setTags((prevTags) => getAvailableAssetProperties(prevTags, assetPropertyData));
  }, [assetPropertyData]);
  useEffect(() => {
    if (fetchAssetProperties) {
      (async () => {
        setIsLoading(true);
        const pumpTags = await api.get(
          'sites/' + customerId + '/sites/' + siteId + '/assets/tags/PUMP'
        );
        const waterTowerTags = await api.get(
          'sites/' + customerId + '/sites/' + siteId + '/assets/tags/WATER_TOWER'
        );

        setTags([
          ...(Array.isArray(pumpTags.data)
            ? pumpTags.data.map((tag) => ({ value: tag.id, label: tag.name, available: true }))
            : []),
          ...(Array.isArray(waterTowerTags.data)
            ? waterTowerTags.data.map((tag) => ({
                value: tag.id,
                label: tag.name,
                available: true,
              }))
            : []),
        ]);
        setIsLoading(false);
      })();
    }
  }, [customerId, siteId, fetchAssetProperties]);

  return (
    <FormControl fullWidth>
      <Label sx={{ backgroundColor: theme.palette.grey[200] }}>Asset Property</Label>
      <Select color="primary" value={!isLoading && tags.length ? value : ''} {...props}>
        {tags.map((tag) => (
          <MenuItem disabled={!tag.available} key={tag.value} value={`${tag.value}_${tag.label}`}>
            {tag.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AssetPropertySelect;
