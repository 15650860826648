import { Theme, styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const StyledFilterButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  background: theme.palette.primary.lighter,
  color: theme.palette.primary.main,
}));

const filterBtnBox = {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  margin: '16px 0 12px 0',
};

export { StyledFilterButton, filterBtnBox };
