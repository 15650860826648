import { Button, Stack, SvgIcon, Typography } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import DragList from 'src/components/streametric/drag/DragList';
import { reorderItems } from 'src/components/streametric/drag/reorderItems';
import AssetPropertyDraggable from './AssetPropertyDraggable';
import { v4 as uuid } from 'uuid';
import { AssetProperty } from '../../../types';
import { Add } from '@mui/icons-material';
import AddAssetPropertyDialog from '../add-asset-property/AddAssetPropertyDialog';

interface IAssetPropertiesListProps {
  assetProperties: AssetProperty[];
  onChange: (assetProperties: AssetProperty[]) => void;
}

const AssetPropertiesList: FunctionComponent<IAssetPropertiesListProps> = ({
  assetProperties,
  onChange,
}) => {
  const [openAssetPropertyColumn, setOpenAssetPropertyColumn] = useState(false);
  const [assetProperty, setAssetProperty] = useState<AssetProperty | null>(null);

  return (
    <Stack gap="8px">
      <Typography variant="body1">Asset Properties</Typography>
      <DragList
        id="asset_properties"
        sx={{ gap: '8px' }}
        onDragEnd={(result) => {
          reorderItems(assetProperties, result, (reorderedItems) => {
            onChange(reorderedItems);
          });
        }}
      >
        {assetProperties.map((property, index: number) => (
          <AssetPropertyDraggable
            item={property}
            onEdit={(data) => {
              setAssetProperty(data);
              setOpenAssetPropertyColumn(true);
            }}
            onRemove={(data) => {
              onChange(assetProperties.filter((assetProp) => assetProp.id !== data.id));
            }}
            key={property.id}
            index={index}
          />
        ))}
      </DragList>
      <Stack flexDirection="row" justifyContent="flex-end">
        <Button
          onClick={() => {
            setOpenAssetPropertyColumn(true);
          }}
          variant="text"
          color="primary"
        >
          <Stack flexDirection="row" gap="12px">
            <SvgIcon component={Add} />
            <Typography variant="body1">Add column</Typography>
          </Stack>
        </Button>
      </Stack>

      <AddAssetPropertyDialog
        assetPropertieslist={assetProperties}
        onClose={() => {
          setAssetProperty(null);
          setOpenAssetPropertyColumn(false);
        }}
        onCancel={() => {
          setAssetProperty(null);
          setOpenAssetPropertyColumn(false);
        }}
        assetProperty={assetProperty}
        onSubmit={(data) => {
          setAssetProperty(null);
          const newAssetProperty: AssetProperty = {
            id: uuid(),
            assetProperty: {
              value: (data.assetProperty as string).split('_')[0],
              label: (data.assetProperty as string).split('_')[1],
            },
            unit: {
              value: (data.unit as string).split('_')[0],
              label: (data.unit as string).split('_')[1],
            },
            aggregation: {
              value: (data.aggregation as string).split('_')[0],
              label: (data.aggregation as string).split('_')[1],
            },
            columnStatistics: {
              TOTAL: data.TOTAL as boolean,
              MIN: data.MIN as boolean,
              MAX: data.MAX as boolean,
              AVERAGE: data.AVERAGE as boolean,
            },
          };
          if (assetProperty) {
            onChange(
              assetProperties.map((assetProp) =>
                assetProp.id === assetProperty.id ? newAssetProperty : assetProp
              )
            );
          } else {
            onChange([...assetProperties, newAssetProperty]);
          }

          setOpenAssetPropertyColumn(false);
        }}
        open={openAssetPropertyColumn}
      />
    </Stack>
  );
};

export default AssetPropertiesList;
