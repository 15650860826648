import { Container, Stack } from '@mui/material';
import { ReportPreview, SideBarFilter } from '../components';
import { useResponsive } from 'src/hooks';
import { useFilters } from '../hooks';
import AssetReportsSidebar from '../components/asset-report-sidebar/AssetReportsSidebar';
import useReportPreviewState from '../hooks/useReportPreviewState';
import ReportPreviewLoading from '../components/report-preview-loading/ReportPreviewLoading';
import NoReportGeneratedComponent from '../components/no-report-generated/NoReportGeneratedComponent';
import dayjs from 'dayjs';
import { useAuthentication } from 'src/features/authentication/context';

export const AssetReportsPage = () => {
  const isTablet = useResponsive('down', 'lg');
  const { state, handleReportCancel, handleReportsPreview } = useReportPreviewState();
  const { isOpen, selectedItems, handleSelectedItemsChange, toggleSidebar } = useFilters();
  const { sites } = useAuthentication();

  let content;
  if (state.status === 'LOADING') {
    content = <ReportPreviewLoading onCancel={handleReportCancel} />;
  } else if (!state.data) {
    content = <NoReportGeneratedComponent />;
  } else {
    content = (
      <ReportPreview
        data={state.data}
        title={`${state.data.name} - ${dayjs(state.data.timeframe.start).format(
          'MMM D YYYY'
        )} - ${dayjs(state.data.timeframe.end).format('MMM D YYYY')}`}
      />
    );
  }

  return (
    <Container maxWidth={false} disableGutters data-testId="reports-page">
      <SideBarFilter
        isOpen={isOpen}
        selectedItems={selectedItems}
        handleSelectedItemsChange={handleSelectedItemsChange}
        sites={sites}
        toggleSidebar={toggleSidebar}
      />
      <Stack
        flexDirection={isTablet ? 'column' : 'row'}
        gap="24px"
        flex="1"
        sx={{
          marginTop: '24px',
        }}
      >
        <AssetReportsSidebar toggleSidebar={toggleSidebar} onSubmit={handleReportsPreview} />
        {content}
      </Stack>
    </Container>
  );
};
