import { Theme, styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

type StylesTypes = {
  theme: Theme;
  isActive: boolean;
};

const StyledTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme, isActive }: StylesTypes) => ({
  color: isActive ? theme.palette.grey[800] : theme.palette.grey[500],
  fontWeight: isActive ? '700' : '400',
}));

const StledExtendedNavItem = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme, isActive }: StylesTypes) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '8px',
  padding: '4px 0px',
  cursor: 'pointer',
  borderBottom: isActive ? `2px solid ${theme.palette.primary.main} ` : '2px solid transparent',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
}));

const iconBox = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export { StyledTitle, StledExtendedNavItem, iconBox };
