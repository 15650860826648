/* eslint-disable no-restricted-globals */
export const worker = () => {
  self.addEventListener('message', async (e) => {
    if (!e || !e.data) {
      self.postMessage({ status: 'error', error: 'Invalid message received in worker.' });
      return;
    }

    const { gatewayId, startDate, endDate, measurements, aggregationType, statistics } = e.data;

    if (!gatewayId) {
      self.postMessage({ status: 'error', error: 'Missing gatewayId in message.' });
      return;
    }

    const queryParams = new URLSearchParams({
      ...(startDate && { start_date: startDate }),
      ...(endDate && { end_date: endDate }),
      ...(measurements && { measurements }),
      ...(aggregationType && { aggregation_type: aggregationType }),
      ...(statistics && { statistics }),
    }).toString();

    const url = `${process.env.REACT_APP_STREAMETRIC_API}/v2/data/${gatewayId}/trends?${queryParams}`;

    try {
      const response = await fetch(url, {
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      self.postMessage({ status: 'success', response: responseData });
    } catch (error) {
      console.error('Error fetching data:', error);
      self.postMessage({ status: 'error', error: error.message });
    }
  });
};
