export const INTEGRATIONS_LABEL = 'Integrations';
export const BREADCRUMBS_ROOT = 'Sites';
export const CONNECT_DATA_SOURCE = 'Connect Data Source';
export const BREADCRUMBS_EDITFORM = 'Edit Data Source:';
export const PAYLOAD_INTERVAL_TOOLTIP = 'Expected maximum time between payloads (info only)';
export const DATA_SOURCE_TYPE_TOOLTIP =
  'To change data source type, new data source needs to be added.';
export const LAST_EDITED_LABEL = 'Last edited on: ';
export const EDIT_ACTION_NAME = 'Save Changes';
export const CANCEL_ACTION_NAME = 'Cancel';
export const ERROR_MESSAGE = 'Please enter all mandatory fields';
export const WEATHER_SOURCE_NAME = 'Weather Forecast';
export const WEATHER_DATA_SOURCE_TYPE = 'weather_api';
export const WEATHER_DATA_SOURCE_TYPE_DISPLAY_NAME = 'Weather API';
export const WEATHER_DATA_SOURCE_INTERVAL = '60';

export const WEATHER_DATA_TAG_TYPE = 'Weather Data';

export const TABLE_HEAD = [
  { id: 'data-source-id', label: 'Data Source ID', align: 'left', testId: 'data-source-id-header' },
  {
    id: 'data-source-name',
    label: 'Name',
    align: 'left',
    testId: 'name-header',
  },
  {
    id: 'data-source-type',
    label: 'Data Source Type',
    align: 'left',
    testId: 'data-source-type-heaer',
  },
  { id: 'status', label: 'Status', align: 'left', testId: 'status-header' },
  { id: '', testId: 'edit-data-source-header' },
];

export const RAW_INPUT_DATA_SOURCE_TABLE_HEAD = [
  { id: 'rawInput', label: 'Raw Input Names', align: 'left' },
  {
    id: 'dataType',
    label: 'Tag Type',
    align: 'left',
  },
  { id: 'rawUnit', label: 'Raw Unit', align: 'left' },
  { id: 'displayNames', label: 'Tag Display Name', align: 'left' },
  { id: 'hide', label: 'Hide', align: 'center' },
];

export const WEATHER_DATA_SOURCE_TABLE_HEAD = [
  { id: 'rawInput', label: 'Raw Input Names', align: 'left' },
  {
    id: 'dataType',
    label: 'Tag Type',
    align: 'left',
  },
  { id: 'rawUnit', label: 'Raw Unit', align: 'left' },
  { id: 'displayNames', label: 'Tag Display Name', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'hide', label: 'Hide', align: 'center' },
];
