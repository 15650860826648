import { Stack, Typography, useTheme } from '@mui/material';
import Chart from 'react-apexcharts';
import { useGatewayReadings } from 'src/features/sites/hooks';
import { chartSeries, getChartOptions } from '../../config';
import { NoDataAvailableBox } from '../NoDataAvailableBox';
import { cardChartLabel } from '../style';
import WidgetWrapper from '../WidgetWrapper';

type Props = {
  type: 'continuous' | 'discrete';
  title: string;
  unit: string;
  isEditMode?: boolean;
  widgetId?: string;
  values: any[] | null;
  toggleAddOrRemoveHandler?: () => void;
  testId?: string;
  gatewayInput: any;
};

export const TrendWidget = ({
  type,
  title,
  unit,
  isEditMode,
  widgetId,
  values,
  toggleAddOrRemoveHandler,
  testId,
  gatewayInput,
}: Props) => {
  const options = getChartOptions(type);
  const theme = useTheme();
  const gatewayId = gatewayInput?.gatewayId;
  const tagId = gatewayInput?.id;

  const { readings, isDataReady } = useGatewayReadings({
    gatewayId: gatewayId || '',
    tagId: tagId || '',
  });

  const rawInputNameReadings = readings && readings[tagId];
  const readingsValues = rawInputNameReadings && rawInputNameReadings.values;

  const newData = readingsValues && Object.entries(readingsValues);

  const formatNewData = newData?.flatMap((reading: any) => {
    const formattedTimestamp = reading[0] && new Date(reading[0]);

    const localTime = new Date(
      formattedTimestamp.getTime() - formattedTimestamp.getTimezoneOffset() * 60 * 1000
    );

    return {
      timestamp: localTime,
      input: reading[1] && reading[1].toString(),
      inputName: rawInputNameReadings && rawInputNameReadings.displayName,
      rawUnit: rawInputNameReadings && rawInputNameReadings.rawUnit,
    };
  });

  const series = isDataReady ? chartSeries(values ?? formatNewData) : [];

  const noFormatedData = formatNewData?.length === 0 || !formatNewData;

  const noDataAvailable = !values && noFormatedData;

  return (
    <WidgetWrapper
      title={title}
      isEditMode={isEditMode}
      widgetId={widgetId!}
      toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
      testId={testId}
      isDataReady={isDataReady}
      isTrendWidget={true}
      options={options}
      series={series}
      typeChart="area"
      unit={unit}
      noDataAvailable={noDataAvailable}
    >
      <Stack sx={cardChartLabel}>
        <Typography variant="caption" sx={{ color: theme.palette.grey[500] }}>
          {unit}
        </Typography>
      </Stack>
      {noDataAvailable ? (
        <NoDataAvailableBox />
      ) : (
        <Stack>
          <Chart options={options} series={series} type="area" width="100%" height="100%" />
        </Stack>
      )}
    </WidgetWrapper>
  );
};

export default TrendWidget;
