import { Box, Checkbox } from '@mui/material';
import { useEffect } from 'react';
import { TagResponse } from 'src/features/alarm-rules/types';
import { useResponsive } from 'src/hooks';
import { SEPARATOR } from '../../config';
import {
  StyledAssetNameLabel,
  StyledGatewayNameLabel,
  gatewayNameBox,
  assetNameLabelBox,
  StyledDisplayName,
  modalContentSide,
  StyledModalContentSide,
  StyledModalContentRow,
} from './style';

type ModalContentRowProps = {
  values: any;
  tag: TagResponse;
  disabled?: boolean;
  siteName: string;
  siteId: string;
  setValue: (name: string, value: string) => void;
  isParticularChart?: boolean;
};

export const ModalContentRow = ({
  tag,
  siteName,
  values,
  disabled = false,
  setValue,
  siteId,
  isParticularChart,
}: ModalContentRowProps) => {
  const key = `row${SEPARATOR}${siteId}${SEPARATOR}${siteName}${SEPARATOR}${
    tag.rawInputName
  }${SEPARATOR}${tag.rawUnit}${SEPARATOR}${tag.gatewayId}${SEPARATOR}${
    tag.id
  }${SEPARATOR}${tag.displayName?.replace(/\./g, '(dot)')}`;
  useEffect(() => {
    if (isParticularChart) return;
    setValue(key, 'false');
  }, [isParticularChart, setValue, siteId, tag, key]);

  const isMobile = useResponsive('down', 'sm');
  return siteId && tag.gatewayId && tag.id && tag.displayName && !tag.hidden && siteName ? (
    <StyledModalContentRow
      sx={{ cursor: disabled ? 'default' : 'none' }}
      onClick={(e) => {
        e.stopPropagation();
        if (disabled) return;
        setValue(key, (!(values[key] === 'true')).toString());
      }}
    >
      <Box sx={modalContentSide}>
        <Checkbox
          disabled={disabled}
          name={key}
          inputProps={{
            // @ts-ignore
            'data-sm': 'tag-checkbox',
          }}
          checked={values[key] === 'true'}
          onChange={(event) => {
            setValue(key, event.target.checked.toString());
          }}
        />

        <StyledDisplayName data-sm={tag?.displayName} isMobile={isMobile}>
          {tag?.displayName}
        </StyledDisplayName>
      </Box>
      <StyledModalContentSide isMobile={isMobile}>
        {tag?.gatewayName ? (
          <Box sx={gatewayNameBox}>
            <StyledGatewayNameLabel variant="caption">{tag.gatewayName}</StyledGatewayNameLabel>
          </Box>
        ) : null}
        {tag?.connectedAsset ? (
          <Box sx={assetNameLabelBox}>
            <StyledAssetNameLabel variant="caption">{tag.connectedAsset}</StyledAssetNameLabel>
          </Box>
        ) : null}
      </StyledModalContentSide>
    </StyledModalContentRow>
  ) : null;
};
