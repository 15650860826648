import { FunctionComponent, useEffect, useState } from 'react';
import { FormFooter, FormWrapperBackdrop } from '../styles';
import FormWrapper from './FormWrapper';
import FormRenderer from 'src/features/formRenderer/components/FormRenderer';
import FormActions from './FormActions';
import { Box, IconButton, Stack, SvgIcon, useTheme } from '@mui/material';
import FormHeader from './FormHeader';
import FormHistory from './FormHistory';
import { FormSchema } from 'src/features/formRenderer/types';
import { Mode } from '@mui/icons-material';
import { ApiState } from '../types';
import { api } from 'src/api.client';
import { useAuthentication } from 'src/features/authentication/context';
import { CustomWaterLoading } from 'src/components';
import { useSnackbar } from 'notistack';

interface ILogFormProps {
  id: string;
  logType?: 'enter' | 'edit';
  onClose: () => void;
  isDefault: boolean;
}

const LogForm: FunctionComponent<ILogFormProps> = ({
  onClose,
  id,
  isDefault,
  logType = 'enter',
}) => {
  const theme = useTheme();
  const {
    customerId: { value: customerId },
  } = useAuthentication();

  const [formState, setFormState] = useState<ApiState<FormSchema | null>>({
    data: null,
    loading: true,
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      try {
        let response;
        if (isDefault) {
          response = await api.get('/sites/global/forms/' + id);
        } else {
          response = await api.get('/sites/' + customerId + '/forms/' + id);
        }

        setFormState({ loading: false, data: response.data });
      } catch (err) {
        onClose();
        enqueueSnackbar('Could not load form', { variant: 'error' });
        setFormState({ loading: false, data: null });
      }
    })();
  }, [customerId, id, isDefault, enqueueSnackbar, onClose]);

  const selectedForm = formState.data;
  return (
    <>
      <FormWrapperBackdrop onClick={onClose} />
      <FormWrapper>
        {formState.loading || !selectedForm ? (
          <Stack
            sx={{ width: '100%', height: ' 100%' }}
            alignItems="center"
            justifyContent="center"
          >
            <CustomWaterLoading />
          </Stack>
        ) : (
          <>
            <FormHeader
              action={
                logType === 'edit' && (
                  <IconButton>
                    <SvgIcon sx={{ color: theme.palette.grey[400] }} component={Mode} />
                  </IconButton>
                )
              }
              title={`${logType === 'enter' ? 'Enter: ' : 'Edit: '}${selectedForm.name}`}
            />
            <Box sx={{ flex: 1, marginBottom: '70px', overflow: 'auto' }}>
              <FormRenderer
                formActionsComponent={
                  <FormFooter>
                    {selectedForm.updated_at && (
                      <FormHistory lastEdited={selectedForm.updated_at} />
                    )}
                    <FormActions isEdit={logType === 'edit'} onCancel={onClose} />
                  </FormFooter>
                }
                onSubmit={(data) => {
                  console.log(data);
                }}
                schema={selectedForm as FormSchema}
              />
            </Box>
          </>
        )}
      </FormWrapper>
    </>
  );
};

export default LogForm;
