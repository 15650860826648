import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledIconBox = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[200],
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px',
  cursor: 'pointer',
}));

export { StyledIconBox };
