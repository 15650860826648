import { useLocation } from 'react-router';
import FloatingAddLog from '../components/FloatingAddLog';
import LogForm from '../components/LogForm';
import { MenuPopoverItem } from 'src/components';
import { api } from 'src/api.client';
import { useAuthentication } from 'src/features/authentication/context';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ApiState } from '../types';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';

const Logs = () => {
  const location = useLocation();
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const logType = searchParams.get('logType');
  const { customerId } = useAuthentication();

  const { append, get, remove } = useQueryParamsActions();

  const [formsState, setFormsState] = useState<
    ApiState<
      {
        name: string;
        id: string;
        isDefault: boolean;
      }[]
    >
  >({
    loading: true,
    data: [],
  });
  const formId = get('formId');
  const form = formsState.data.find((form) => form.id === formId);
  const fetchedFormsRef = useRef<boolean>(false);

  const onClose = useCallback(() => {
    remove('formId');
  }, [remove]);

  useEffect(() => {
    if (!fetchedFormsRef.current) {
      (async () => {
        const response = await api.get('/sites/' + customerId.value + '/forms/');
        setFormsState({ loading: false, data: response.data });
        fetchedFormsRef.current = true;
      })();
    }
  }, [customerId.value]);

  return (
    <div>
      {form && (
        <LogForm
          logType={logType ? (logType as 'enter' | 'edit') : 'enter'}
          onClose={onClose}
          isDefault={form.isDefault}
          id={form.id}
        />
      )}
      <FloatingAddLog
        disabled={formsState.loading || !formsState.data}
        renderActions={(onClose) => (
          <>
            {formsState.data.map((form) => (
              <MenuPopoverItem
                key={form.id}
                handleClick={() => {
                  onClose();
                  append('formId', form.id);
                }}
                customContent={<>{form.name}</>}
              />
            ))}
          </>
        )}
      />
    </div>
  );
};

export default Logs;
