import { Button, Stack, useTheme } from '@mui/material';
import { FunctionComponent } from 'react';
import SectionTitle from './SectionTitle';
import { BlockStack, CustomTextArea } from '../styles';
import { useFormContext } from 'react-hook-form';

const CommentsField: FunctionComponent = () => {
  const theme = useTheme();
  const { watch, setValue } = useFormContext();
  return (
    <BlockStack>
      <SectionTitle>Comments</SectionTitle>
      <CustomTextArea
        onChange={(event) => setValue('comments', event.target.value.slice(0, 2000))}
        maxRows={3}
        minRows={3}
        value={watch('comments')}
        name="comments"
      />
      <Stack sx={{ paddingBlock: theme.spacing(1) }} flexDirection="row" justifyContent="flex-end">
        <Button variant="outlined">Add comment</Button>
      </Stack>
    </BlockStack>
  );
};

export default CommentsField;
