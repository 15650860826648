import React, { Suspense, SyntheticEvent } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { GetAllResponse } from 'src/services/sites';

type Props = {
  selectedItems: string[];
  handleSelectedItemsChange: (event: SyntheticEvent, ids: string[]) => void;
  sites: { value: GetAllResponse[] | null; loaded: boolean };
};

const FilterTreeCategoryItem = React.lazy(
  () => import('../filter-tree-category-item/FilterTreeCategoryItem')
);

export const FilterTreeCategoryList = ({
  selectedItems,
  handleSelectedItemsChange,
  sites,
}: Props) => (
  <Box sx={{ marginTop: '10px' }}>
    <Suspense fallback={<CircularProgress size="small" />}>
      <SimpleTreeView
        checkboxSelection
        selectedItems={selectedItems}
        onSelectedItemsChange={handleSelectedItemsChange}
        multiSelect
      >
        {sites?.value?.map((site) => (
          <FilterTreeCategoryItem key={site.id} id={site.id} label={site.siteName} />
        ))}
      </SimpleTreeView>
    </Suspense>
  </Box>
);
