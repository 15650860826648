import { FunctionComponent } from 'react';
import { Field as FieldT, FieldType } from '../types';
import AssetField from './AssetField';
import AttachmentField from './AttachmentField';
import SelectField from './SelectField';
import NotesField from './NotesField';
import DataEntryField from './DataEntryField';

const fields: Record<FieldType, any> = {
  selection: SelectField,
  notes: NotesField,
  data_entry: DataEntryField,
  asset: AssetField,
  attachment: AttachmentField,
};

const Field: FunctionComponent<{
  blockElement: boolean;
  field: FieldT;
  groupName?: string;
  fieldsLength: number;
}> = ({ field, groupName, fieldsLength, blockElement }) => {
  const CustomInput = fields[field.type];
  const fieldId = groupName ? groupName + '.' + field.id : field.id;

  return (
    <CustomInput
      blockElement={blockElement}
      fieldsLength={fieldsLength}
      field={{ ...field, id: fieldId }}
    />
  );
};

export default Field;
