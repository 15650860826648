import { FormControl, MenuItem, Select, SelectProps, useTheme } from '@mui/material';
import { FunctionComponent } from 'react';
import units from '../../../../gateway-details/data/raw-units.json';
import Label from 'src/components/minimals/form/Label';

const UnitSelect: FunctionComponent<SelectProps> = (props) => {
  const theme = useTheme();
  return (
    <FormControl>
      <Label sx={{ backgroundColor: theme.palette.grey[200] }}>Unit</Label>
      <Select label="Unit" {...props}>
        {units.map((unit) => (
          <MenuItem key={unit.value} value={`${unit.value}_${unit.label}`}>
            {unit.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default UnitSelect;
