import {
  MBR_TRAIN_ASSET,
  PUMP_ASSET,
  VALVE_ASSET,
  WATER_TOWER_ASSET,
  WET_WELL_ASSET,
} from 'src/variables';
import {
  BOOSTER_PUMP_STATION,
  COMBINED_SEWAGE_OVERFLOW,
  LIFT_STATION,
  PROCESS_PLANT,
  WASTEWATER_TREATMENT_PLANT,
  WATER_TOWER,
  WATER_TREATMENT_PLANT,
  WATER_WELL,
} from 'src/variables/siteTypes';
import { Option } from 'src/features/formRenderer/types';

const TABLE_HEAD_REPORTS = [
  { id: 'siteName', label: 'Site Name', align: 'left', testId: 'report-site-name-header' },
  {
    id: 'asset',
    label: 'Asset',
    align: 'left',
    testId: 'report-asset-header',
  },
  {
    id: 'dailyRuntime',
    label: 'Daily Runtime[Hours]',
    align: 'left',
    testId: 'report-daily-runtime-header',
  },
  {
    id: 'dailyCycles',
    label: 'Daily Cycles',
    align: 'left',
    testId: 'report-daily-cycles-header',
  },
  {
    id: 'dailyVolumetricFlow',
    label: 'Daily Volumetric Flow',
    align: 'left',
    testId: 'report-daily-volumetric-flow-header',
  },
  {
    id: 'averageDailyEfficiency',
    label: 'Average Daily Efficiency',
    align: 'left',
    testId: 'report-average-daily-efficiency-header',
  },
  {
    id: 'totalDailyEnergyConsumption',
    label: 'Total Daily Energy Consumption',
    align: 'left',
    testId: 'report-total-daily-energy-consumption-header',
  },
];

const TABLE_HEAD_REPORTS_NEW = [
  { id: 'type', label: 'Type', align: 'left', testId: 'report-type-header' },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    testId: 'report-name-header',
  },
  {
    id: 'createdBy',
    label: 'Created By',
    align: 'left',
    testId: 'report-created-by-header',
  },
  {
    id: 'modified',
    label: 'Modified',
    align: 'left',
    testId: 'report-modified-header',
  },
  {
    id: 'schedule',
    label: 'Schedule',
    align: 'left',
    testId: 'report-schedule-header',
  },
  {
    id: 'favorite',
    label: 'Favorite',
    align: 'left',
    testId: 'report-favorite-header',
  },
  { id: '', testId: 'context-menu-header' },
];

const ASSET_REPORTS_FILTER_CATEGORIES = [
  {
    type: 'Site Type',
    options: [
      { label: 'Lift Station', type: LIFT_STATION },
      { label: 'Wastewater Treatment Plant ', type: WASTEWATER_TREATMENT_PLANT },
      { label: 'Water Treatment Plant', type: WATER_TREATMENT_PLANT },
      { label: 'Booster Pump Station', type: BOOSTER_PUMP_STATION },
      { label: 'Water Tower', type: WATER_TOWER },
      { label: 'Process Plant', type: PROCESS_PLANT },
      { label: 'Combined Sewage Overflow', type: COMBINED_SEWAGE_OVERFLOW },
      { label: 'Water Well', type: WATER_WELL },
    ],
  },
  {
    type: 'Asset Type',
    options: [
      { label: 'Pump', type: PUMP_ASSET },
      { label: 'Water Tower', type: WATER_TOWER_ASSET },
      { label: 'Wet Well', type: WET_WELL_ASSET },
      { label: 'MBR Train', type: MBR_TRAIN_ASSET },
      { label: 'Valve', type: VALVE_ASSET },
    ],
  },
];

const TIME_FRAME_OPTIONS = [
  { value: 'this_week', label: 'This Week' },
  { value: 'this_month', label: 'This Month' },
  { value: 'this_year', label: 'This Year' },
  { value: 'last_week', label: 'Last Week' },
  { value: 'last_month', label: 'Last Month' },
  { value: 'last_year', label: 'Last Year' },
  { value: 'last_7_days', label: 'Last 7 Days' },
  { value: 'last_30_days', label: 'Last 30 Days' },
  { value: 'last_365_days', label: 'Last 365 Days' },
];

const TABLE_HEAD_REPORTS_HISTORY = [
  { id: 'type', label: 'Type', align: 'left', testId: 'report-type-header' },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    testId: 'report-name-header',
  },
  {
    id: 'createdBy',
    label: 'Created By',
    align: 'left',
    testId: 'report-created-by-header',
  },
  {
    id: 'modified',
    label: 'Modified',
    align: 'left',
    testId: 'report-modified-header',
  },
  { id: '', testId: 'context-menu-header' },
];

const DATE_AGGREGATION_OPTIONS = [
  { value: 'DAILY', label: 'Daily' },
  { value: 'WEEKLY', label: 'Weekly' },
  { value: 'MONTHLY', label: 'Monthly' },
];

const COLUMN_STATISTICS = [
  {
    value: 'TOTAL',
    label: 'Total',
  },
  {
    value: 'MIN',
    label: 'Minimum',
  },
  {
    value: 'AVERAGE',
    label: 'Average',
  },
  {
    value: 'MAX',
    label: 'Maximum',
  },
];

const ASSET_REPORT_DEFAULT_COLUMNS = [
  {
    name: 'date',
    label: 'Date',
  },
  {
    name: 'site_name',
    label: 'Site Name',
  },
  {
    name: 'site_type',
    label: 'Site Type',
  },
  {
    name: 'asset_name',
    label: 'Asset Name',
  },
  {
    name: 'asset_type',
    label: 'Asset Type',
  },
];

const AGGREGATION_SELECT_OPTIONS: Omit<Option, 'order'>[] = [
  {
    value: 'total',
    label: 'Total',
  },
  {
    value: 'average',
    label: 'Average',
  },
  {
    value: 'min',
    label: 'Min',
  },
  {
    value: 'max',
    label: 'Max',
  },
];
const DATA_REPORT_DEFAULT_COLUMNS = [
  {
    name: 'date',
    label: 'Date',
  },
  {
    name: 'time',
    label: 'Time',
  },
];

export {
  DATE_AGGREGATION_OPTIONS,
  COLUMN_STATISTICS,
  AGGREGATION_SELECT_OPTIONS,
  ASSET_REPORT_DEFAULT_COLUMNS,
  DATA_REPORT_DEFAULT_COLUMNS,
  TABLE_HEAD_REPORTS_HISTORY,
  TABLE_HEAD_REPORTS,
  TABLE_HEAD_REPORTS_NEW,
  ASSET_REPORTS_FILTER_CATEGORIES,
  TIME_FRAME_OPTIONS,
};
