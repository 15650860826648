import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FunctionComponent } from 'react';

interface IFormHistoryProps {
  lastEdited: string;
}

const FormHistory: FunctionComponent<IFormHistoryProps> = ({ lastEdited }) => (
  <Stack sx={{ paddingBlock: '28px' }}>
    <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
      Last edited: {dayjs(lastEdited).format('M/D/YYYY, h:mm A')}
    </Typography>
  </Stack>
);

export default FormHistory;
