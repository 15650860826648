import { Box, Fab, Stack, styled } from '@mui/material';

export const FormWrapperBox = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.default,
  borderRadius: '16px',
  overflow: 'hidden',
  position: 'fixed',
  zIndex: 1299,

  padding: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    top: '50%',
    maxHeight: 'calc(100% - 32px)',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
  },
  [theme.breakpoints.down('md')]: {
    left: '16px',
    right: '16px',
    height: '80%',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

export const StyledFloatingActionButton = styled(Fab)(() => ({
  position: 'fixed',
  borderRadius: '50px',
  width: 'auto',
  right: '16px',
  paddingLeft: '19px',
  paddingRight: '13px',
  bottom: '16px',
}));

export const FormWrapperBackdrop = styled(Box)(() => ({
  position: 'fixed',
  inset: '0',
  zIndex: 1298,
  background: 'rgba(53, 57, 57, 0.45)',
  cursor: 'pointer',
}));

export const FormFooter = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  width: '100%',
}));

export const FormHeaderStack = styled(Stack)(() => ({}));
