import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Stack, SvgIcon, useTheme } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormWrapperBackdrop } from 'src/features/logs/styles';

interface IContentPreviewBoxProps {
  content: File[];
  currentImageOrder: number;
  onClose: () => void;
  renderContentItem: (file: File) => JSX.Element;
}

const ContentPreviewBox: FunctionComponent<IContentPreviewBoxProps> = ({
  content,
  renderContentItem,
  currentImageOrder,
  onClose,
}) => {
  const theme = useTheme();
  const [currentOrder, setCurrentOrder] = useState(currentImageOrder);
  const portal = createPortal(
    <>
      <FormWrapperBackdrop sx={{ zIndex: 1300 }} onClick={onClose} />
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          maxWidth: '342px',
          maxHeight: '400px',
          width: '100%',
          height: '100%',
          borderRadius: '16px',
          position: 'fixed',
          zIndex: 1301,
          boxShadow: ' -40px 40px 80px -8px rgba(145, 158, 171, 0.24)',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 32px)',
          },
        }}
      >
        {renderContentItem(content[currentOrder])}

        <Stack
          alignItems="center"
          onClick={() => {
            setCurrentOrder(currentOrder + 1);
          }}
          justifyContent="center"
          sx={{
            position: 'absolute',
            top: '0',
            cursor: 'pointer',
            right: '0px',
            width: '40px',
            height: '100%',
            pointerEvents: !content[currentOrder + 1] ? 'none' : 'initial',
            opacity: !content[currentOrder + 1] ? '0.5' : 'initial',
          }}
        >
          <SvgIcon component={KeyboardArrowRight} />
        </Stack>
        <Stack
          onClick={() => {
            setCurrentOrder(currentOrder - 1);
          }}
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'absolute',
            top: '0',
            left: '0px',
            cursor: 'pointer',

            width: '40px',
            height: '100%',
            pointerEvents: !content[currentOrder - 1] ? 'none' : 'initial',
            opacity: !content[currentOrder - 1] ? '0.5' : 'initial',
          }}
        >
          <SvgIcon component={KeyboardArrowLeft} />
        </Stack>
      </Box>
    </>,
    document.body
  );
  return <>{portal}</>;
};

export default ContentPreviewBox;
