import { FunctionComponent } from 'react';
import { Box, useTheme } from '@mui/material';
import RHFDatePicker from 'src/components/minimals/form/RHFDatePicker';
import { useFormContext } from 'react-hook-form';
import { BlockStack } from '../styles';

const DateRangeField: FunctionComponent = () => {
  const theme = useTheme();
  const { watch } = useFormContext();
  const fromDateKey = 'date_from';
  const toDateKey = 'date_to';
  const fromDateValue = watch(fromDateKey);
  const toDateValue = watch(toDateKey);
  return (
    <BlockStack flexDirection="row">
      <Box sx={{ flex: 1, marginRight: theme.spacing(1) }}>
        <RHFDatePicker
          rules={{ required: true }}
          maxDate={toDateValue}
          label="Start Date / Time"
          name={fromDateKey}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <RHFDatePicker
          rules={{ required: true }}
          minDate={fromDateValue}
          label="End Date / Time"
          name={toDateKey}
        />
      </Box>
    </BlockStack>
  );
};

export default DateRangeField;
