import { useFormContext, Controller, ValidationRule } from 'react-hook-form';
import { Autocomplete, AutocompleteProps, SxProps, Theme, useAutocomplete } from '@mui/material';
import { CustomPaper } from '../custom-paper';

interface RHFAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  captureInputChange?: boolean;
  rules?: { [key: string]: ValidationRule | ValidationRule[] };
  customListboxProps?: ReturnType<ReturnType<typeof useAutocomplete>['getListboxProps']> & {
    sx?: SxProps<Theme>;
    ref?: React.Ref<Element>;
  };
}

export const RHFAutocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  name,
  rules,
  customListboxProps,
  captureInputChange,
  ...other
}: RHFAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={''}
      render={({ field: { onChange, ..._field } }) => (
        <Autocomplete
          PaperComponent={CustomPaper}
          onChange={(_, data) => onChange(data)}
          onInputChange={captureInputChange ? (_, data) => onChange(data) : undefined}
          {..._field}
          {...other}
          ListboxProps={{ ...customListboxProps, className: 'custom-autocomplete-scrollbar' }}
        />
      )}
    />
  );
};
