import { Stack, Box, Typography, Divider } from '@mui/material';
import { titleBox, titleStyle, formGroup } from './style';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

type Props = {
  title: string;
  options: { type: string; label: string }[];
};

export const FilterCategoryItem = ({ title, options }: Props) => (
  <Stack>
    <Box sx={titleBox}>
      <Typography sx={titleStyle}>{title}</Typography>
    </Box>
    <FormGroup sx={formGroup}>
      <FormControlLabel control={<Checkbox defaultChecked />} label="Select All" />
      <Divider />
      {options.map((option) => (
        <FormControlLabel
          key={option.type}
          control={<Checkbox defaultChecked />}
          label={option.label}
        />
      ))}
    </FormGroup>
  </Stack>
);
