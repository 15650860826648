import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { ListIcon } from 'src/assets';

import { TENANT_SWITCH_PATH } from 'src/routes/config';

type Props = {
  handleAccountSwitch: () => void;
};

export const SwitchAccountBtn = ({ handleAccountSwitch }: Props) => (
  <Button
    data-testid="switch-btn"
    variant="outlined"
    startIcon={<ListIcon width={18} height={18} />}
    to={TENANT_SWITCH_PATH}
    component={Link}
    onClick={handleAccountSwitch}
  >
    Switch Account
  </Button>
);
