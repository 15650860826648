import { DialogProps, SelectChangeEvent, Stack } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import AddColumnDialog from '../../sidebar/columns/AddColumnDialog';
import AssetPropertySelect from './AssetPropertySelect';
import AggregationSelect from './AssetAggregationSelect';
import { AssetProperty } from '../../../types';
import AssetPropertyDataProvider from 'src/features/reports/context/assetPropertyContext';
import ColumnAggregations from '../../sidebar/add-column-inputs/ColumnAggregations';
import UnitSelect from '../../sidebar/add-column-inputs/UnitSelect';

const AddAssetPropertyDialog: FunctionComponent<
  Omit<DialogProps, 'onSubmit'> & {
    assetProperty: AssetProperty | null;
    assetPropertieslist: AssetProperty[];
    onSubmit: (data: Record<string, boolean | string>) => void;
    onCancel: () => void;
  }
> = ({ onSubmit, onCancel, assetPropertieslist, assetProperty, ...props }) => {
  const [assetState, setAssetState] = useState<Record<string, string>>({});
  const [columnStatistics, setColumnStatistics] = useState<Record<string, boolean>>(
    assetProperty?.columnStatistics || {
      total: false,
      minimum: false,
      maximum: false,
      average: false,
    }
  );

  useEffect(() => {
    if (assetProperty) {
      setAssetState({
        assetProperty: `${assetProperty.assetProperty.value}_${assetProperty.assetProperty.label}`,
        aggregation: `${assetProperty.aggregation.value}_${assetProperty.aggregation.label}`,
        unit: `${assetProperty.unit.value}_${assetProperty.unit.label}`,
      });
      setColumnStatistics(assetProperty.columnStatistics);
    } else {
      setColumnStatistics({
        total: false,
        minimum: false,
        maximum: false,
        average: false,
      });
      setAssetState({});
    }
  }, [assetProperty, props.open, setAssetState]);

  const onChange = (event: SelectChangeEvent<unknown>, name: string) => {
    setAssetState((prevState) => ({
      ...prevState,
      [name]: (event.target?.value || '') as string,
    }));
  };
  return (
    <AddColumnDialog
      isEdit={!!assetProperty}
      disabled={!assetState.assetProperty || !assetState.aggregation}
      onSubmit={() => onSubmit({ ...columnStatistics, ...assetState })}
      onCancel={onCancel}
      columnStatistics={columnStatistics}
      {...props}
    >
      <AssetPropertyDataProvider
        value={{
          assetProperties: assetPropertieslist,
          currentAssetProperty: assetProperty,

          currentState: assetState,
        }}
      >
        <Stack gap="24px">
          <AssetPropertySelect
            fetchAssetProperties={props.open}
            value={assetState.assetProperty ?? ''}
            onChange={(event) => onChange(event, 'assetProperty')}
          />
          <AggregationSelect
            value={assetState.aggregation ?? ''}
            onChange={(event) => onChange(event, 'aggregation')}
          />
          <UnitSelect value={assetState.unit ?? ''} onChange={(event) => onChange(event, 'unit')} />
        </Stack>
      </AssetPropertyDataProvider>
      <ColumnAggregations
        value={columnStatistics}
        onChange={(value) => setColumnStatistics(value)}
      />
    </AddColumnDialog>
  );
};

export default AddAssetPropertyDialog;
