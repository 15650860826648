import { Box, Button } from '@mui/material';
import { ExportIcon } from 'src/assets';
import { StyledReportPreviewHeader, StyledTitle, btnsBox } from './style';

type Props = {
  title: string;
};

export const ReportPreviewHeader = ({ title }: Props) => (
  <StyledReportPreviewHeader>
    <StyledTitle>{title}</StyledTitle>
    <Box sx={btnsBox}>
      <Button size="small" variant="outlined" startIcon={<ExportIcon width={18} height={18} />}>
        Export
      </Button>
      <Button size="small" variant="contained">
        Save Report
      </Button>
    </Box>
  </StyledReportPreviewHeader>
);
