import React from 'react';
import { TableCell, Typography, Checkbox } from '@mui/material';
import { RHFTextField } from 'src/components/minimals/form';
import { WEATHER_DATA_TAG_TYPE } from '../config';
import { useAuthentication } from 'src/features/authentication/context';
import { AccessRoles } from 'src/features/user-account-details/types';

type Props = {
  input: any;
  values: any;
  errors: any;
  handleChange: (isChecked: boolean, name: string, target: any) => void;
};

export const EditWeatherDataSourceForm: React.FC<Props> = ({
  input,
  values,
  errors,
  handleChange,
}) => {
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
    getCurrentRole,
  } = useAuthentication();
  const isSiteEmployee = getCurrentRole(customerId, siteId) === AccessRoles.SITE_EMPLOYEE;

  return (
    <>
      <TableCell
        sx={{
          maxWidth: 'sm',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          flexGrow: 1,
          width: '20%',
        }}
      >
        <Typography variant="subtitle2">{input.rawInputName}</Typography>
      </TableCell>
      <TableCell
        sx={{
          maxWidth: 'sm',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          flexGrow: 1,
          width: '20%',
        }}
      >
        <Typography variant="subtitle2">{WEATHER_DATA_TAG_TYPE}</Typography>
      </TableCell>
      <TableCell
        sx={{
          maxWidth: 'sm',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          flexGrow: 1,
          width: '20%',
        }}
      >
        <Typography variant="subtitle2">mm</Typography>
      </TableCell>
      <TableCell
        sx={{
          maxWidth: 'sm',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          flexGrow: 1,
          width: '23%',
        }}
      >
        <RHFTextField
          name={`${input.id}displayName`}
          testId={`${input.rawInputName}-tagDisplayName`}
          rules={{
            required: 'Tag Display Name is required',
          }}
          error={!!errors[`${input.id}displayName`]}
          helperText={errors[`${input.id}displayName`]?.message}
          disabled={isSiteEmployee}
        />
      </TableCell>
      <TableCell
        sx={{
          maxWidth: 'sm',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          flexGrow: 1,
          width: '23%',
        }}
      >
        <Typography variant="subtitle2">Online</Typography>{' '}
        {/* TODO: check with backend on how we are getting this value */}
      </TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        <Checkbox
          inputProps={{ 'data-sm': `${input.rawInputName}-hidden` } as any}
          checked={values[`${input.id}hidden`] === 'true'}
          name={`${input.id}hidden`}
          disabled={isSiteEmployee}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(event.target.checked, `${input.id}hidden`, event)
          }
        />
      </TableCell>
    </>
  );
};
