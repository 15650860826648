import { FormControl } from '@mui/material';
import { LocalizationProvider, TimePicker, TimePickerProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import { Controller, useFormContext, ValidationRule } from 'react-hook-form';

interface IRHFTimePicekrProps extends TimePickerProps<Date> {
  name: string;
  rules?: { [key: string]: ValidationRule | ValidationRule[] };
}

const RHFTimePicker: FunctionComponent<IRHFTimePicekrProps> = ({ name, rules, ...props }) => {
  const { control } = useFormContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl sx={{ width: '100%' }} component="fieldset">
        <Controller
          render={({ field }) => (
            <TimePicker
              sx={{ width: '100%' }}
              {...props}
              views={['hours', 'minutes', 'seconds']}
              className="date-picker"
              value={field.value ? (dayjs(field.value) as any) : null}
              onChange={(date) => field.onChange(date?.toISOString())}
            />
          )}
          rules={rules}
          name={name}
          control={control}
        />
      </FormControl>
    </LocalizationProvider>
  );
};

export default RHFTimePicker;
