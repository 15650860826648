import { FormControl, MenuItem, Select, SelectProps } from '@mui/material';
import { FunctionComponent } from 'react';
import { DATE_AGGREGATION_OPTIONS } from '../../../config/config';
import Label from 'src/components/minimals/form/Label';

interface IDateAggregationSelectProps {
  timeFrames?: { value: string; label: string }[];
}

const DateAggregationSelect: FunctionComponent<IDateAggregationSelectProps & SelectProps> = ({
  timeFrames = DATE_AGGREGATION_OPTIONS,
  ...props
}) => (
  <FormControl size="small" fullWidth>
    <Label>Aggregation Interval</Label>

    <Select size="small" label="Data Aggregation" {...props}>
      {timeFrames.map((timeFrame) => (
        <MenuItem key={timeFrame.value} value={timeFrame.value}>
          {timeFrame.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default DateAggregationSelect;
