import { Container, Stack } from '@mui/material';
import { ReportsHeader } from '../components';
import { useResponsive } from 'src/hooks';
import { Outlet } from 'react-router';
import { useForm } from 'react-hook-form';
import { FormProvider } from 'src/components/minimals/form';
import { FormDataType } from '../types';
import timezones from '../data/timezones.json';

const BuildReport = ({ title }: { title: string }) => {
  const isTablet = useResponsive('down', 'lg');
  const methods = useForm<FormDataType>({
    defaultValues: {
      header: {
        reportType: '',
        timeZone: timezones[0],
      },
      data: {
        timeFrame: 'this_week',
        aggregationInterval: 'DAILY',
      },
    },
  });

  return (
    <Container maxWidth={false} disableGutters data-testId="reports-page">
      <FormProvider methods={methods}>
        <ReportsHeader title={title} />
        <Stack
          flexDirection={isTablet ? 'column' : 'row'}
          gap="24px"
          sx={{
            marginTop: '24px',
          }}
        >
          <Outlet context={{ title }} />
        </Stack>
      </FormProvider>
    </Container>
  );
};

export default BuildReport;
