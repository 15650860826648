import { FunctionComponent } from 'react';
import { FormSchema } from '../types';
import { FormProvider, useForm } from 'react-hook-form';
import { formatFormFields, orderElements } from '../utils/formUtils';
import { isSection } from '../guards';
import Section from './Section';
import '../styles.css';
import Field from './Field';
import CommentsField from './CommentsField';
import FormRendererContextProvider from '../formRenderer.context';
import DateField from './DateField';
import DateRangeField from './DateRangeField';
import { Box, useTheme } from '@mui/material';

interface IFormRendererProps {
  onSubmit: (data: any) => void;
  schema: FormSchema;
  formData?: Record<string, any>;
  formActionsComponent: JSX.Element;
}

const FormRenderer: FunctionComponent<IFormRendererProps> = ({
  onSubmit,
  schema,
  formActionsComponent,
}) => {
  const methods = useForm({
    defaultValues: formatFormFields(schema),
  });
  const theme = useTheme();

  return (
    <FormRendererContextProvider schema={schema}>
      <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Box sx={{ flexBasis: '100%', paddingBlock: theme.spacing(2) }}>
            {schema.date_type === 'DATE' ? <DateField /> : <DateRangeField />}
          </Box>
          {orderElements(schema).map((element) =>
            isSection(element) ? (
              <Section key={element.id} {...element} />
            ) : (
              <Field key={element.id} fieldsLength={3} blockElement={false} field={element} />
            )
          )}
          {schema.allow_comments && <CommentsField />}
          {formActionsComponent}
        </FormProvider>
      </form>
    </FormRendererContextProvider>
  );
};

export default FormRenderer;
