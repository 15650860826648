import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { ACCOUNT_ISSUE_PATH, LOGIN_PATH, REPORT_NEW_HEADER } from '../../../config';
import { AppLayout } from 'src/layouts';
import {
  NAV_ITEMS_HEADER_SITES_MANAGEMENT,
  getNavItemsHeaderSiutesManagementSAAndAA,
} from 'src/features';
import { LoadingElement } from '../../LoadingElement';
import { AccessRoles } from 'src/features/user-account-details/types';
import { ASSET_REPORT_NAV_ITEMS } from '../config';
import BuildReport from 'src/features/reports/pages/BuildReport';
import { ReportsService } from 'src/services';

type Props = {
  loggedIn: {
    value: boolean;
    loaded: boolean;
  };
  isSuperAdmin: boolean;
  isCustomerAccessAllowed: {
    value: boolean;
    loaded: boolean;
    isCustomerActive: boolean;
    isUserActive: boolean;
  };
  customerId: {
    value: string | null;
    loaded: boolean;
  };
};

const ReportAssetRouteElement: FunctionComponent<any> = ({ isSuperAdmin }) => {
  const { isCustomerAccessAllowed, loggedIn, customerId, getCurrentRole, user } =
    useAuthentication();
  const isAccountAdmin = getCurrentRole(customerId.value, null) === AccessRoles.ACCOUNT_ADMIN;
  const [reportCount, setReportCount] = useState<number | null>(null);
  const defaultTitle = useMemo(
    () => `New Report${reportCount ? ' ' + reportCount : ''}`,
    [reportCount]
  );
  const [title, setTitle] = useState(defaultTitle);

  useEffect(() => {
    if (!customerId.value) return;
    const fetchReportCount = async () => {
      try {
        const response = await ReportsService.getCount(customerId.value as string);
        setReportCount(response);
      } catch (err) {
        console.error(err);
      }
    };

    fetchReportCount();
  }, [customerId]);
  const getUniqueRedirectPath = () => {
    if (!loggedIn.value) return LOGIN_PATH;
    if (!isSuperAdmin && !isCustomerAccessAllowed.value)
      return ACCOUNT_ISSUE_PATH + '?reason=Access Denied';
  };

  return (
    <Protected
      isAuthenticated={loggedIn.value}
      isAuthorized={isSuperAdmin || isCustomerAccessAllowed.value}
      uniqueRedirectPath={getUniqueRedirectPath()}
    >
      <AppLayout
        onTitleChange={(title) => {
          setTitle(title);
        }}
        extendedHeaderTitle={title}
        defaultExtendedHeaderTitle={defaultTitle}
        isExtendedHeader={true}
        extendedNavItems={ASSET_REPORT_NAV_ITEMS}
        headerTitle={REPORT_NEW_HEADER}
        headerNavItems={
          isSuperAdmin || isAccountAdmin
            ? getNavItemsHeaderSiutesManagementSAAndAA(user, isSuperAdmin)
            : NAV_ITEMS_HEADER_SITES_MANAGEMENT
        }
      >
        <BuildReport title={title} />
      </AppLayout>
    </Protected>
  );
};

export const BuildReportsRouteElement = ({
  loggedIn,
  isSuperAdmin,
  isCustomerAccessAllowed,
  customerId,
}: Props) => {
  if (!loggedIn.loaded) return <LoadingElement />;

  if (loggedIn.loaded && !loggedIn.value)
    return <ReportAssetRouteElement isSuperAdmin={isSuperAdmin} />;

  if (!customerId.value || !customerId.loaded) return <LoadingElement />;

  if (isSuperAdmin && loggedIn.loaded)
    return <ReportAssetRouteElement loggedIn={loggedIn} isSuperAdmin={isSuperAdmin} />;

  if (!isCustomerAccessAllowed.loaded) return <LoadingElement />;

  return <ReportAssetRouteElement isSuperAdmin={isSuperAdmin} />;
};
