import { IconButton, Box, Typography, useTheme, Dialog, Tooltip } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { MoreVerticalFillIcon, EditWidgetIcon, CloseIcon } from 'src/assets';
import { CustomWaterLoading, MenuPopover } from 'src/components';
import { MenuPopoverItem } from './MenuPopoverItem';
import { TrendModalContent } from './TrendModalContent/TrendModalContent';
import { useHtmlElement, useResponsive, useToggle } from 'src/hooks';
import { card, cardHeader, cardTitle, cardContext, wrapperIconsBox, spinnerBox } from './style';
import { ModalRemoveContent } from './ModalRemoveContent';
import { WidgetService } from 'src/services';
import { useAuthentication } from 'src/features/authentication/context';
import { AccessRoles } from 'src/features/user-account-details/types';
import { ExpandIcon } from 'src/assets/icons/ExpandIcon';
import { AlarmPriority } from 'src/types/alarms';
import { HandlePriority } from './HandlePriority/HandlePriority';

type Props = {
  children: React.ReactNode;
  title: string;
  width?: number;
  height?: number;
  isEditMode?: boolean;
  widgetId?: string;
  toggleAddOrRemoveHandler?: () => void;
  testId?: string;
  isDataReady: boolean;
  isTrendWidget?: boolean;
  options?: ApexOptions;
  series?: any;
  typeChart?: 'area' | 'bar';
  unit?: string;
  isPumpWidget?: boolean;
  isControllerWidget?: boolean;
  noDataAvailable?: boolean;
  isValveWidget?: boolean;
  assetPriority?: AlarmPriority;
};

export const WidgetWrapper = ({
  children,
  title,
  width,
  height,
  isEditMode,
  widgetId,
  toggleAddOrRemoveHandler,
  testId,
  isDataReady = false,
  isTrendWidget = false,
  isPumpWidget = false,
  options,
  series,
  typeChart,
  unit,
  isControllerWidget = false,
  isValveWidget = false,
  noDataAvailable,
  assetPriority,
}: Props) => {
  const theme = useTheme();
  const greyColor = theme.palette.grey[500];
  const redColor = theme.palette.error.main;

  const { htmlElement, addHtmlElement, removeHtmlElement } = useHtmlElement();
  const isDesktop = useResponsive('up', 'lg');
  const isTablet = useResponsive('up', 'sm');
  const { state, onToggle } = useToggle();
  const { state: isTrendDialogOpen, onToggle: onTrendDialogToggle } = useToggle();
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
    getCurrentRole,
  } = useAuthentication();

  const onRemoveHandler = () => {
    onToggle();
  };

  const onCloseDialog = () => {
    if (state) onToggle();
  };

  const onCloseTrendDialog = () => {
    if (isTrendDialogOpen) onTrendDialogToggle();
  };

  const removeWidgetHandler = async () => {
    if (!customerId || !siteId || !widgetId) return;
    try {
      await WidgetService.remove({ customerId: customerId, siteId: siteId, widgetId: widgetId });
      if (toggleAddOrRemoveHandler) toggleAddOrRemoveHandler();
      onCloseDialog();
    } catch (error) {
      console.error(error);
    }
  };

  function isOverflown(element: any) {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }

  const els = document.getElementsByClassName('widget-wrapper-title');

  for (var i = 0; i < els.length; i++) {
    const el = els[i];
    const content = els[i]?.textContent ?? '';
    const contentLength = content.length;

    if (el) {
      el.textContent = '';
      for (var j = 0; j < contentLength; j++) {
        el.textContent = content.substring(0, j + 1);

        if (isOverflown(el)) {
          el.textContent = content.substring(0, j - 3);
          el.textContent += '...';
          break;
        }
      }
    }
  }

  return (
    <>
      <Dialog onClose={onCloseDialog} open={state}>
        <ModalRemoveContent
          headerTitle="Remove Widget"
          width={712}
          widgetName={title}
          onRemove={removeWidgetHandler}
          onClose={onCloseDialog}
        />
      </Dialog>

      {isTrendWidget ? (
        <Dialog onClose={onCloseTrendDialog} open={isTrendDialogOpen}>
          <TrendModalContent
            title={title}
            options={options}
            series={series}
            onClose={onCloseTrendDialog}
            typeChart={typeChart}
            unit={unit}
            noDataAvailable={noDataAvailable}
          />
        </Dialog>
      ) : null}
      <Box
        display="div"
        sx={card(
          width!,
          height!,
          isDesktop,
          isTrendWidget,
          isPumpWidget,
          isControllerWidget,
          isValveWidget
        )}
        data-sm={testId}
      >
        <Box display="div" sx={cardHeader}>
          <Tooltip title={title}>
            <Typography variant="h6" sx={cardTitle} className="widget-wrapper-title">
              {title}
            </Typography>
          </Tooltip>

          <Box sx={wrapperIconsBox}>
            {isPumpWidget ? (
              <Box sx={{ marginTop: '0.2rem', display: 'flex' }}>
                <HandlePriority priority={assetPriority} />
              </Box>
            ) : null}
            {isEditMode ? (
              <Box sx={{ height: '20px', marginLeft: '1rem' }}>
                <EditWidgetIcon fill={greyColor} />
              </Box>
            ) : null}
            <Box display="div" sx={cardContext}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {isTrendWidget && isTablet ? (
                  <Box
                    onClick={onTrendDialogToggle}
                    sx={{ margin: '0.3rem 0.5rem 0 1rem', cursor: 'pointer' }}
                  >
                    <ExpandIcon fill={greyColor} />
                  </Box>
                ) : null}
                {getCurrentRole(customerId, siteId) !== AccessRoles.SITE_EMPLOYEE ? (
                  <Box>
                    <IconButton
                      color={'default'}
                      data-sm="context-menu-button"
                      onClick={(event) => addHtmlElement(event.currentTarget)}
                    >
                      <MoreVerticalFillIcon />
                    </IconButton>
                  </Box>
                ) : null}
              </Box>

              <MenuPopover open={htmlElement} onClose={removeHtmlElement} arrow="right-top">
                <MenuPopoverItem
                  icon={<CloseIcon width={10} fill={redColor} />}
                  description={'Remove'}
                  handleClick={onRemoveHandler}
                  testId="remove-button"
                  color={redColor}
                />
              </MenuPopover>
            </Box>
          </Box>
        </Box>
        {isDataReady ? (
          children
        ) : (
          <Box sx={spinnerBox}>
            <CustomWaterLoading />
          </Box>
        )}
      </Box>
    </>
  );
};

export default WidgetWrapper;
