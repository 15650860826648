import { TableFooter } from '@mui/material';
import { TableFooterRow } from './components';

type Props = {
  data: Record<string, number>;
  columns: { key: string; value: string }[];
};

export const TableFooterComponent = ({ data, columns }: Props) => {
  const entries = Object.entries(data);

  return (
    <TableFooter>
      {entries.map(([key, value]: any) => {
        const tableRowItem = { title: key, vals: [] } as { title: string; vals: any };

        columns.forEach((item) => {
          tableRowItem.vals.push(value[item.key]);
        });

        return (
          <TableFooterRow key={key} title={tableRowItem.title} cols={tableRowItem.vals.slice(1)} />
        );
      })}
    </TableFooter>
  );
};
