import { AssetStateType } from 'src/features/reports/types';

export const getAvailableAssetProperties = (
  assetProperties: { value: string; label: string }[],
  assetPropertyData: AssetStateType | null
) =>
  assetProperties.map((tag) => {
    if (
      !assetPropertyData?.assetProperties ||
      !assetPropertyData.assetProperties.length ||
      !assetPropertyData.currentState ||
      !assetPropertyData.currentState.aggregation
    )
      return { ...tag, available: true };

    const assetPropertiesWithTag = assetPropertyData.assetProperties.filter(
      (assetProperty) => assetProperty.assetProperty.value === tag.value
    );
    if (!assetPropertiesWithTag.length) return { ...tag, available: true };
    const currentStateAggregationValue = assetPropertyData.currentState.aggregation.split('_')[0];

    return {
      ...tag,
      available:
        assetPropertiesWithTag.findIndex(
          (element) =>
            element.aggregation.value === currentStateAggregationValue &&
            element.id !== assetPropertyData.currentAssetProperty?.id
        ) === -1,
    };
  });
