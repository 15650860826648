import dayjs from 'dayjs';

const convertToISO = (date: dayjs.Dayjs) => {
  const jsDate = date.toDate();

  jsDate.setHours(0, 0, 0, 0);

  return jsDate.toISOString();
};

export const timeFrameOptions = [
  {
    value: 'this_week',
    label: 'This Week',
    parseToDate: () => {
      const today = dayjs();
      const startOfWeek = today.subtract(today.day() - 1, 'day');
      const endOfWeek = startOfWeek.add(6, 'day');

      return [convertToISO(startOfWeek), convertToISO(endOfWeek)];
    },
  },
  {
    value: 'this_month',
    label: 'This Month',
    parseToDate: () => {
      const startOfMonth = dayjs().startOf('month');
      const endOfMonth = dayjs().endOf('month');

      return [convertToISO(startOfMonth), convertToISO(endOfMonth)];
    },
  },
  {
    value: 'this_year',
    label: 'This Year',
    parseToDate: () => {
      const startOfYear = dayjs().startOf('year');
      const endOfYear = dayjs().endOf('year');

      return [convertToISO(startOfYear), convertToISO(endOfYear)];
    },
  },
  {
    value: 'last_week',
    label: 'Last Week',
    parseToDate: () => {
      const today = dayjs();
      const startOfLastWeek = today.subtract(today.day() + 6, 'day');
      const endOfLastWeek = startOfLastWeek.add(6, 'day');

      return [convertToISO(startOfLastWeek), convertToISO(endOfLastWeek)];
    },
  },
  {
    value: 'last_month',
    label: 'Last Month',
    parseToDate: () => {
      const today = dayjs();
      const startOfLastMonth = today.subtract(1, 'month').startOf('month');
      const endOfLastMonth = today.subtract(1, 'month').endOf('month');

      return [convertToISO(startOfLastMonth), convertToISO(endOfLastMonth)];
    },
  },
  {
    value: 'last_year',
    label: 'Last Year',
    parseToDate: () => {
      const today = dayjs();
      const startOfLastYear = today.subtract(1, 'year').startOf('year');
      const endOfLastYear = today.subtract(1, 'year').endOf('year');

      return [convertToISO(startOfLastYear), convertToISO(endOfLastYear)];
    },
  },
  {
    value: 'last_7_days',
    label: 'Last 7 Days',
    parseToDate: () => {
      const today = dayjs();
      const sevenDaysAgo = today.subtract(7, 'day');

      return [convertToISO(sevenDaysAgo), convertToISO(today)];
    },
  },
  {
    value: 'last_30_days',
    label: 'Last 30 Days',
    parseToDate: () => {
      const today = dayjs();
      const sevenDaysAgo = today.subtract(30, 'day');

      return [convertToISO(sevenDaysAgo), convertToISO(today)];
    },
  },
  {
    value: 'last_365_days',
    label: 'Last 365 Days',
    parseToDate: () => {
      const today = dayjs();
      const sevenDaysAgo = today.subtract(365, 'day');

      return [convertToISO(sevenDaysAgo), convertToISO(today)];
    },
  },
] as const;
