import React from 'react';
import { TableCell, Typography, Checkbox, TextField } from '@mui/material';
import { RHFAutocomplete, RHFSelect, RHFTextField } from 'src/components/minimals/form';
import { filterOptions } from 'src/utilities';
import { DropdownFields, RawUnits } from '../types/DataSource';
import { useAuthentication } from 'src/features/authentication/context';
import { AccessRoles } from 'src/features/user-account-details/types';
import { useResponsive } from 'src/hooks';

type Props = {
  input: any;
  index: number;
  values: any;
  errors: any;
  handleFormFieldInputChange: (value: string, index: number, field: string, name: string) => void;
  handleChange: (isChecked: boolean, name: string, target: any) => void;
  determineRawUnit: (index: number) => RawUnits[];
};

export const EditFlowDataSourceForm: React.FC<Props> = ({
  input,
  index,
  values,
  errors,
  handleFormFieldInputChange,
  handleChange,
  determineRawUnit,
}) => {
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
    getCurrentRole,
  } = useAuthentication();
  const isSiteEmployee = getCurrentRole(customerId, siteId) === AccessRoles.SITE_EMPLOYEE;
  const isMobile = useResponsive('down', 'md');

  const dataTypes: DropdownFields[] = require('../data/data-types.json');

  return (
    <>
      <TableCell
        sx={{
          maxWidth: 'sm',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          flexGrow: 1,
          width: '20%',
        }}
      >
        <Typography variant="subtitle2">{input.rawInputName}</Typography>
      </TableCell>
      <TableCell
        sx={{
          maxWidth: 'sm',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          flexGrow: 1,
          width: '23%',
        }}
      >
        {!isMobile ? (
          <RHFAutocomplete
            name={input.id + 'dataType'}
            options={dataTypes.map((dataType) => dataType.value)}
            filterOptions={(options: string[], state: any) => {
              const labels = dataTypes.map((dataType) => dataType.label);
              return filterOptions(options, state, labels);
            }}
            disabled={isSiteEmployee}
            onChange={(event, newValue) =>
              handleFormFieldInputChange(
                newValue as string,
                index,
                'dataType',
                input.id + 'dataType'
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  'data-sm': input.rawInputName + '-dataType',
                }}
              />
            )}
          />
        ) : (
          <RHFSelect
            name={input.id + 'dataType'}
            options={dataTypes}
            disabled={isSiteEmployee}
            onChange={(event) =>
              handleFormFieldInputChange(
                event.target.value as string,
                index,
                'dataType',
                input.id + 'dataType'
              )
            }
            optional={true}
            testId={input.rawInputName + '-dataType'}
          />
        )}
      </TableCell>
      <TableCell
        sx={{
          maxWidth: 'sm',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          flexGrow: 1,
          width: '23%',
        }}
      >
        {!isMobile ? (
          <RHFAutocomplete
            name={input.id + 'rawUnit'}
            options={determineRawUnit(index).map((rawUnit) => rawUnit.value)}
            filterOptions={(options: string[], state: any) => {
              const labels = determineRawUnit(index).map((rawUnit) => rawUnit.label);
              return filterOptions(options, state, labels);
            }}
            disabled={!values[input.id + 'dataType'] || isSiteEmployee}
            onChange={(event, newValue) =>
              handleFormFieldInputChange(newValue as string, index, 'rawUnit', input.id + 'rawUnit')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  'data-sm': input.rawInputName + '-rawUnit',
                }}
              />
            )}
          />
        ) : (
          <RHFSelect
            name={input.id + 'rawUnit'}
            options={determineRawUnit(index)}
            disabled={!values[input.id + 'dataType'] || isSiteEmployee}
            onChange={(event) =>
              handleFormFieldInputChange(
                event.target.value as string,
                index,
                'rawUnit',
                input.id + 'rawUnit'
              )
            }
            optional={true}
            testId={input.rawInputName + '-rawUnit'}
          />
        )}
      </TableCell>
      <TableCell
        sx={{
          maxWidth: 'sm',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          flexGrow: 1,
          width: '23%',
        }}
      >
        <RHFTextField
          name={input.id + 'displayName'}
          testId={input.rawInputName + '-tagDisplayName'}
          rules={{
            required: 'Tag Display Name is required',
          }}
          error={!!errors[`${input.id}displayName`]}
          helperText={errors[`${input.id}displayName`]?.message}
          disabled={isSiteEmployee}
        />
      </TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        <Checkbox
          inputProps={{ 'data-sm': `${input.rawInputName}-hidden` } as any}
          checked={values[input.id + 'hidden'] === 'true'}
          name={input.id + 'hidden'}
          disabled={isSiteEmployee}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(event.target.checked, input.id + 'hidden', event)
          }
        />
      </TableCell>
    </>
  );
};
