import { Typography, Card, Stack, useTheme } from '@mui/material';

const NoReportGeneratedComponent = () => {
  const theme = useTheme();
  return (
    <Card sx={{ backgroundColor: theme.palette.background.default, width: '100%' }}>
      <Stack
        alignItems="center"
        height="100%"
        minHeight="450px"
        width="100%"
        justifyContent="center"
      >
        <Typography textAlign="center" variant="h6">
          No report currently generated.
          <br /> Click on 'Preview' to generate your first report template.
        </Typography>
      </Stack>
    </Card>
  );
};

export default NoReportGeneratedComponent;
