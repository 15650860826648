import { Box } from '@mui/material';
import { FunctionComponent } from 'react';
import RHFDatePicker from 'src/components/minimals/form/RHFDatePicker';

const DateField: FunctionComponent = () => (
  <Box sx={{ flex: 1 }}>
    <RHFDatePicker rules={{ required: true }} label="Start date / Time" name="from_date" />
  </Box>
);

export default DateField;
