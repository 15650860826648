import { api, READINGS, PUMP_REPORT, REPORTS, API_V2, CUSTOMERS, TEMPLATES } from '../api';
import { GetAllByRangeParams } from './types';

const getAll = async (customerId: string, startDate: string, endDdate: string) => {
  try {
    const response = await api({
      method: 'get',
      url: `v2/reports?customerId=${customerId}&startDate=${startDate}&endDate=${endDdate}&reportType=pump_report`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getCount = async (customerId: string) => {
  try {
    const response = await api({
      method: 'get',
      url: `${API_V2}/${REPORTS}/${CUSTOMERS}/${customerId}/${TEMPLATES}/count`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const saveReportTemplate = async (customerId: string, data: any) => {
  const response = await api({
    method: 'POST',
    url: `v2/${
      data.report_type === 'DATA' ? 'data-reports' : 'reports'
    }/customers/${customerId}/templates`,
    data,
  });
  return response.data;
};

const previewReport = async (customerId: string, data: any, controller: AbortController) => {
  const response = await api({
    method: 'POST',
    signal: controller.signal,
    url: `v2/data-reports/customers/${customerId}/generator`,
    data: { dataReportPreviewTemplate: data },
  });
  return response.data;
};

const getAllByRange = async (params: GetAllByRangeParams) => {
  try {
    const response = await api({
      method: 'get',
      url: `${READINGS}/${params.customerId}${PUMP_REPORT}/between?fromDate=${params.fromDate}&toDate=${params.toDate}&limit=${params.limit}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const ReportsService = {
  getAll,
  getCount,
  getAllByRange,
  saveReportTemplate,
  previewReport,
};
