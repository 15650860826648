import { useRef, useState } from 'react';
import { ApiResourceState } from '../types';
import { useAuthentication } from 'src/features/authentication/context';
import { ReportsService } from 'src/services';
import { useSnackbar } from 'notistack';
import { useFormContext } from 'react-hook-form';
import { useOutletContext } from 'react-router';
import { parseTemplateDTO } from '../utils/saveReport';

type ReportState = {
  status: ApiResourceState;
  error: Error | null;
  data: any;
};

const useReportPreviewState = () => {
  const abortControllerRef = useRef<AbortController>(new AbortController());
  const initialState: ReportState = {
    status: 'INITIAL',
    error: null,
    data: null,
  };
  const [reportsPreviewState, setReportsPreviewState] = useState<ReportState>(initialState);
  const { getValues } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  const { title } = useOutletContext<any>();

  const {
    customerId: { value: customerId },
  } = useAuthentication();

  const handleReportsPreview = async () => {
    const data = getValues();
    setReportsPreviewState((prevState) => ({ ...prevState, status: 'LOADING' }));
    try {
      const response = await ReportsService.previewReport(
        customerId!,
        parseTemplateDTO(data.header?.reportType?.id, { ...data, title }),
        abortControllerRef.current
      );

      enqueueSnackbar('Report preview successfully generated!', { variant: 'success' });
      setReportsPreviewState((prevState) => ({ ...prevState, data: response, status: 'SUCCESS' }));
    } catch (err) {
      abortControllerRef.current = new AbortController();
      setReportsPreviewState((prevState) => ({ ...prevState, error: err, status: 'ERROR' }));
      enqueueSnackbar('Could not generate report preview!\n' + err.message, { variant: 'error' });
    }
  };

  const handleReportCancel = () => {
    abortControllerRef.current.abort();
    setReportsPreviewState(initialState);
  };

  return { state: reportsPreviewState, handleReportsPreview, handleReportCancel };
};

export default useReportPreviewState;
