import { Box } from '@mui/material';
import { StyledTitle, editIconBox } from './style';
import { EditIcon } from 'src/assets';

type Props = {
  title: string;
  iconSize: number;
  greyColor: string;
  isTitleEditable?: boolean;
  isMobile?: boolean;
  isDesktop?: boolean;
  toggleEditHandler: () => void;
};

export const DisplayTitleBox = ({
  title,
  iconSize,
  greyColor,
  isMobile = false,
  isDesktop = false,
  isTitleEditable = true,
  toggleEditHandler,
}: Props) => (
  <Box
    sx={{
      display: 'flex',
      position: 'absolute',
      alignItems: 'center',
      width: `calc(100vw -  ${isMobile ? 245 : isDesktop ? 680 : 400}px)`,
    }}
  >
    <StyledTitle sx={{ overflow: 'hidden' }}>{title}</StyledTitle>
    {isTitleEditable && (
      <Box sx={editIconBox} onClick={toggleEditHandler} data-testid="edit-icon-box">
        <EditIcon width={iconSize} height={iconSize} fill={greyColor} />
      </Box>
    )}
  </Box>
);
