import { TableRow, TableCell, Typography, useTheme } from '@mui/material';

type Props = {
  row: any;
};

export const DataReportTableRowItem = ({ row }: Props) => {
  const values = Object.values(row);
  const theme = useTheme();
  return (
    <TableRow hover data-testId="table-row" sx={{ background: theme.palette.common.white }}>
      {values.map((value: any) => (
        <TableCell key={value} data-testId="date-row">
          <Typography variant="subtitle2">{value || '-'}</Typography>
        </TableCell>
      ))}
    </TableRow>
  );
};
