import React from 'react';
import { Box, useTheme } from '@mui/material';
import { IconsTypesProps } from 'src/assets/icons/types';
import { StyledTitle, StledExtendedNavItem, iconBox } from './style';
import { useLocation, useNavigate } from 'react-router';
import { useResponsive } from 'src/hooks';

type Props = {
  title: string;
  url: string;
  icon: React.FunctionComponent<IconsTypesProps>;
  greyColor: string;
  activeItem: string;
  onSetActiveItemHandler: (newActiveItem: string) => void;
};

export const ExtendedNavItem = ({
  icon,
  title,
  greyColor,
  url,
  activeItem,
  onSetActiveItemHandler,
}: Props) => {
  const theme = useTheme();
  const iconComponent = React.createElement(icon, { fill: greyColor });
  const isActive = activeItem === title;
  const isMobile = useResponsive('down', 'md');
  const navigate = useNavigate();
  const location = useLocation();

  const onClickHandler = () => {
    const currentQueryParams = location.search;
    navigate(`${url}${currentQueryParams}`);
    onSetActiveItemHandler(title);
  };
  return (
    <StledExtendedNavItem theme={theme} isActive={isActive} onClick={onClickHandler}>
      <Box sx={iconBox}>{iconComponent}</Box>
      <StyledTitle variant={isMobile ? 'caption' : 'body2'} theme={theme} isActive={isActive}>
        {title}
      </StyledTitle>
    </StledExtendedNavItem>
  );
};
