import { TableRow, TableCell, Typography, IconButton, Stack, useTheme } from '@mui/material';
import { Report, ReportType } from '../../types';
import { AssetReportIcon, DataReportIcon, MoreVerticalFillIcon, PDFReportIcon } from 'src/assets';
import { MenuPopover, MenuPopoverItem } from 'src/components';
import { useHtmlElement } from 'src/hooks';
import { Delete } from '@mui/icons-material';

type TableRowItemProps = {
  row: Report;
  selected: boolean;
};

export const TableRowItemHistory = ({ row, selected }: TableRowItemProps) => {
  const tableCellStyle = { maxWidth: 'sm', whiteSpace: 'normal', wordWrap: 'break-word' };
  const { htmlElement, addHtmlElement, removeHtmlElement } = useHtmlElement();
  const theme = useTheme();

  const modified = row.modified.split('.');
  const modifiedDate = modified[0];
  const modifiedTime = modified[1];

  const handleDelete = () => {};

  const getMenuPopoverItems = () => (
    <>
      <MenuPopoverItem
        icon={<Delete color="error" />}
        customContent={
          <Typography variant="body2" color="error">
            Delete
          </Typography>
        }
        handleClick={handleDelete}
        testId="delete-button"
      />
    </>
  );

  const getReportTypeIcon = (reportType: ReportType) => {
    switch (reportType) {
      case ReportType.ASSET:
        return <AssetReportIcon />;
      case ReportType.PDF:
        return <PDFReportIcon />;
      case ReportType.DATA:
        return <DataReportIcon />;
      default:
        return <PDFReportIcon />;
    }
  };

  return (
    <TableRow sx={{ cursor: 'pointer' }} hover selected={selected} data-sm="table-row">
      <TableCell data-sm="type-row" sx={{ ...tableCellStyle, width: '20px' }}>
        {getReportTypeIcon(row.type)}
      </TableCell>
      <TableCell data-sm="name-row" sx={tableCellStyle}>
        <Typography variant="subtitle2">{row.name ?? '-'}</Typography>
      </TableCell>
      <TableCell data-sm="created-by-row" sx={tableCellStyle}>
        <Typography variant="subtitle2">{row.createdBy ?? '-'}</Typography>
      </TableCell>
      <TableCell data-sm="modified-row" sx={tableCellStyle}>
        <Stack alignItems={'flex-start'} justifyContent={'center'}>
          <Typography variant="subtitle2">{modifiedDate ? modifiedDate + '.' : '-'}</Typography>
          <Typography variant="overline" sx={{ color: theme.palette.grey[500] }}>
            {modifiedTime ?? '-'}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell data-sm="context-menu-row" align="right" sx={{ ...tableCellStyle, width: '20px' }}>
        <IconButton
          data-sm="context-menu-button"
          color={htmlElement ? 'inherit' : 'default'}
          onClick={(event) => addHtmlElement(event.currentTarget)}
        >
          <MoreVerticalFillIcon />
        </IconButton>
        <MenuPopover open={htmlElement} onClose={removeHtmlElement} arrow="right-top">
          {getMenuPopoverItems()}
        </MenuPopover>
      </TableCell>
    </TableRow>
  );
};
