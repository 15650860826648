import { FunctionComponent } from 'react';
import SidebarWrapper from '../sidebar/SidebarWrapper';
import ColumnToggle from '../sidebar/columns/ToggleColumn';
import { Button, Divider, Stack, Typography } from '@mui/material';
import TimeFrameSelect from '../sidebar/sidebar-inputs/TimeFrameSelect';
import DateAggregationSelect from '../sidebar/sidebar-inputs/DateAggregationSelect';
import AssetPropertiesList from './asset-properties-list/AssetPropertiesList';
import { ASSET_REPORT_DEFAULT_COLUMNS } from '../../config/config';
import { useFormContext } from 'react-hook-form';
import { FilterIcon } from 'src/assets/icons/FilterIcon';
import { StyledFilterButton, filterBtnBox } from './style';

interface IAssetReportsSidebarProps {
  onSubmit: () => void;
  toggleSidebar: (state: boolean) => void;
}

const AssetReportsSidebar: FunctionComponent<IAssetReportsSidebarProps> = ({
  onSubmit,
  toggleSidebar,
}) => {
  const { setValue, watch } = useFormContext();

  const toggleColumn = (name: string, value: boolean) => {
    setValue(`data.columns.default.${name}`, value);
  };

  return (
    <SidebarWrapper
      renderFooterContent={(onCollapse) => (
        <Stack flexDirection="row" justifyContent="flex-end">
          <Button
            onClick={() => {
              onCollapse();
              onSubmit();
            }}
            variant="contained"
            color="primary"
          >
            Preview
          </Button>
        </Stack>
      )}
    >
      <Stack gap="20px">
        <TimeFrameSelect
          onChange={(value) => setValue('data.timeFrame', value)}
          value={watch('data.timeFrame')}
        />
        <DateAggregationSelect
          value={watch('data.aggregationInterval')}
          onChange={(event) => setValue('data.aggregationInterval', event.target!.value as string)}
        />
      </Stack>
      <Stack sx={filterBtnBox} onClick={() => toggleSidebar(true)}>
        <StyledFilterButton
          onClick={() => toggleSidebar(true)}
          endIcon={<FilterIcon />}
          variant="contained"
          size="small"
        >
          Filter
        </StyledFilterButton>
      </Stack>
      {/* Default columns */}
      <Stack gap="8px">
        <Typography variant="body1">Default Columns</Typography>
        {ASSET_REPORT_DEFAULT_COLUMNS.map((columnItem) => (
          <ColumnToggle
            key={columnItem.name}
            name={columnItem.name}
            label={columnItem.label}
            enabled={(watch('data.columns.default') || {})[columnItem.name]}
            onToggle={toggleColumn}
          />
        ))}
      </Stack>
      <Divider sx={{ marginBlock: '16px' }} />
      {/* Asset properties */}
      <AssetPropertiesList
        assetProperties={watch('data.columns.custom') || []}
        onChange={(assetProperties) => setValue('data.columns.custom', assetProperties)}
      />
    </SidebarWrapper>
  );
};

export default AssetReportsSidebar;
