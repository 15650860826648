import { Table, TableBody, TableContainer, Card, useTheme, Box } from '@mui/material';
import {
  TableHeadCustom,
  MemoizedScrollbar,
  TablePaginationCustom,
  useTable,
} from 'src/components';
import { ReportPreviewHeader } from '../report-preview-header';
import { TableFooterComponent } from '../table-footer-component';
import { DataReportTableRowItem } from '../data-report-table-row-item';
import { useResponsive } from 'src/hooks';

type Props = {
  title: string;
  data: any;
};

export const ReportPreview = ({ title, data }: Props) => {
  const theme = useTheme();
  const table = useTable();
  const lightGreyColor = theme.palette.grey[200];
  const isMobile = useResponsive('down', 'lg');
  const content = (
    <Card sx={{ width: '100%', minWidth: '1200px' }}>
      <ReportPreviewHeader title={title} />
      <TableContainer>
        <MemoizedScrollbar sx={{ maxHeight: '450px', overflow: 'auto' }}>
          <Table size="medium" data-testId="reports-table">
            <TableHeadCustom
              tableCellStyle={{ background: `${lightGreyColor} !important` }}
              headLabel={data.columns.map((column: any) => ({
                name: column.key,
                label: column.value,
              }))}
            />

            <TableBody data-testId="table-reports-content">
              {data.dataRows?.map((row: any) => (
                <DataReportTableRowItem key={row.id} row={row} />
              ))}
            </TableBody>
            <TableFooterComponent columns={data.columns} data={data.statistics} />
          </Table>
        </MemoizedScrollbar>
        <TablePaginationCustom
          count={data.dataRows?.length || 0}
          testId="reports-table-pagination"
          {...table}
          onPageChange={table.onChangePage}
        />
      </TableContainer>
    </Card>
  );
  return isMobile ? <Box sx={{ width: '100%', overflow: 'auto' }}>{content}</Box> : content;
};
