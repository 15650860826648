import { IconsTypesProps } from './types';
export const StreametricLogo = ({ height = 50, width = 219 }: IconsTypesProps) => (
  <svg width="212" height="68" viewBox="0 0 212 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_17531_63858)">
      <path
        d="M50.9097 53.662C50.8859 54.6499 50.65 55.6211 50.2178 56.5098C49.7856 57.3984 49.1674 58.1837 48.405 58.8124C47.4595 59.5798 46.2678 59.9784 45.0509 59.9342C44.831 59.9216 44.6152 59.8693 44.4141 59.7797C39.226 57.7812 34.0386 55.782 28.8519 53.782C28.7184 53.7282 28.4945 53.7325 28.4961 53.5527C28.4961 53.3509 28.7276 53.3326 28.8794 53.2755C32.3331 51.9637 35.7922 50.6648 39.2416 49.3405C41.1078 48.6241 42.993 47.9512 44.7973 47.0797C45.0691 46.9787 45.3656 46.9647 45.6457 47.0399C48.9885 47.5394 50.9253 49.825 50.9097 53.6609V53.662Z"
        fill="#049ACC"
      />
      <mask
        id="mask0_17531_63858"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="8"
        width={width}
        height={height}
      >
        <path
          d="M25.0469 8H25.4544C27.0047 8.05383 27.8165 9.15357 28.6201 10.2555C28.9853 10.7652 29.3059 11.3055 29.5783 11.8704C31.4758 15.7165 33.6155 19.4366 35.6282 23.2209C37.1085 26.0022 38.6201 28.7686 40.1311 31.5338C41.845 34.671 43.5197 37.8319 45.2788 40.9421C45.34 41.0447 45.3912 41.1528 45.4317 41.2651C45.5614 41.6543 45.4548 41.826 45.05 41.9154C44.3135 42.0374 43.5653 42.0736 42.8204 42.023C42.3113 42.015 41.8078 42.131 41.3535 42.3611C38.0829 43.976 34.7793 45.5015 31.492 47.0798C28.9415 48.3039 26.3842 49.5152 23.8202 50.7138C20.0155 52.4988 16.2205 54.3048 12.4018 56.0591C10.7708 56.8073 9.16073 57.6105 7.47263 58.2301C6.0388 58.8228 4.46382 58.987 2.93856 58.7027C2.36162 58.585 1.82159 58.3299 1.36428 57.959C0.906968 57.5881 0.545887 57.1123 0.311674 56.5721C0.142759 56.0087 0.0382012 55.4279 0 54.8409V53.211C0.113581 53.0242 0.048985 52.8126 0.0624424 52.6189C0.136273 51.7055 0.38673 50.8152 0.799909 49.9974C2.11066 47.1681 3.45532 44.3555 4.92541 41.6037C5.6112 40.3188 6.25016 39.0059 6.92249 37.714C9.00247 33.7171 11.1164 29.7353 13.2265 25.7557C14.4402 23.4654 15.666 21.1818 16.9041 18.9048C18.2919 16.363 19.6214 13.791 21.0264 11.2572C21.3842 10.6422 21.7966 10.0607 22.2586 9.51961C22.5736 9.06739 22.9896 8.69467 23.4735 8.43093C23.9575 8.16718 24.4961 8.01965 25.0469 8Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_17531_63858)">
        <path
          d="M-5.11719 62.4177V3.42578H55.1806V62.4177H-5.11719Z"
          fill="url(#paint0_linear_17531_63858)"
        />
      </g>
      <path
        d="M57.8718 40.0347C56.6948 40.0347 55.5692 39.8581 54.4951 39.505C53.421 39.1371 52.575 38.6663 51.957 38.0925L52.7736 36.371C53.3622 36.886 54.1125 37.3127 55.0248 37.6511C55.937 37.9895 56.886 38.1587 57.8718 38.1587C58.7694 38.1587 59.4977 38.0557 60.0568 37.8497C60.6159 37.6437 61.0279 37.3642 61.2927 37.011C61.5575 36.6432 61.69 36.2312 61.69 35.7751C61.69 35.2454 61.5134 34.8187 61.1603 34.495C60.8219 34.1713 60.3731 33.9139 59.814 33.7226C59.2696 33.5166 58.6664 33.34 58.0043 33.1929C57.3421 33.0458 56.6727 32.8766 55.9959 32.6853C55.3338 32.4793 54.7232 32.2218 54.164 31.9128C53.6196 31.6039 53.1782 31.1919 52.8398 30.6769C52.5014 30.1472 52.3322 29.4704 52.3322 28.6465C52.3322 27.8519 52.5382 27.1236 52.9502 26.4615C53.3769 25.7847 54.0243 25.2476 54.8924 24.8504C55.7752 24.4384 56.8934 24.2324 58.247 24.2324C59.1445 24.2324 60.0347 24.3501 60.9175 24.5855C61.8003 24.821 62.5654 25.1594 63.2128 25.6008L62.4845 27.3664C61.8224 26.925 61.1235 26.6086 60.3878 26.4174C59.6522 26.2114 58.9386 26.1084 58.247 26.1084C57.3789 26.1084 56.6653 26.2187 56.1062 26.4394C55.5471 26.6601 55.1351 26.9544 54.8703 27.3222C54.6202 27.6901 54.4951 28.1021 54.4951 28.5582C54.4951 29.1026 54.6643 29.5366 55.0027 29.8603C55.3558 30.184 55.8046 30.4415 56.349 30.6328C56.9081 30.824 57.5187 31.0006 58.1808 31.1624C58.8429 31.3096 59.505 31.4788 60.1671 31.6701C60.8439 31.8613 61.4546 32.1115 61.999 32.4204C62.5581 32.7294 63.0068 33.1414 63.3452 33.6564C63.6836 34.1713 63.8528 34.8335 63.8528 35.6427C63.8528 36.4225 63.6395 37.1508 63.2128 37.8276C62.7861 38.4897 62.124 39.0268 61.2265 39.4388C60.3437 39.836 59.2255 40.0347 57.8718 40.0347Z"
        fill="#05B6B2"
      />
      <path
        d="M69.9294 39.8581V26.3291H64.6325V24.409H77.4112V26.3291H72.1143V39.8581H69.9294Z"
        fill="#05B6B2"
      />
      <path
        d="M79.8149 39.8581V24.409H85.8401C87.1937 24.409 88.3487 24.6223 89.3051 25.049C90.2615 25.4757 90.9971 26.0937 91.5121 26.9029C92.0271 27.7121 92.2845 28.6759 92.2845 29.7941C92.2845 30.9123 92.0271 31.8761 91.5121 32.6853C90.9971 33.4798 90.2615 34.0904 89.3051 34.5171C88.3487 34.9438 87.1937 35.1571 85.8401 35.1571H81.0288L82.0219 34.1419V39.8581H79.8149ZM90.1437 39.8581L86.2153 34.2523H88.5768L92.5494 39.8581H90.1437ZM82.0219 34.3626L81.0288 33.2812H85.7739C87.1863 33.2812 88.2531 32.9796 88.974 32.3763C89.7097 31.7583 90.0775 30.8976 90.0775 29.7941C90.0775 28.6906 89.7097 27.8372 88.974 27.234C88.2531 26.6307 87.1863 26.3291 85.7739 26.3291H81.0288L82.0219 25.2256V34.3626Z"
        fill="#05B6B2"
      />
      <path
        d="M97.8586 31.0742H105.804V32.9501H97.8586V31.0742ZM98.0573 37.938H107.062V39.8581H95.8503V24.409H106.753V26.3291H98.0573V37.938Z"
        fill="#05B6B2"
      />
      <path
        d="M108.296 39.8581L115.292 24.409H117.477L124.496 39.8581H122.178L115.932 25.6449H116.815L110.569 39.8581H108.296ZM111.276 35.9958L111.871 34.2302H120.567L121.207 35.9958H111.276Z"
        fill="#05B6B2"
      />
      <path
        d="M126.8 39.8581V24.409H128.61L135.54 36.0841H134.569L141.411 24.409H143.22L143.242 39.8581H141.124L141.102 27.7416H141.609L135.518 37.9821H134.503L128.367 27.7416H128.919V39.8581H126.8Z"
        fill="#05B6B2"
      />
      <path
        d="M149.887 31.0742H157.833V32.9501H149.887V31.0742ZM150.086 37.938H159.091V39.8581H147.879V24.409H158.782V26.3291H150.086V37.938Z"
        fill="#05B6B2"
      />
      <path
        d="M165.732 39.8581V26.3291H160.435V24.409H173.214V26.3291H167.917V39.8581H165.732Z"
        fill="#05B6B2"
      />
      <path
        d="M175.617 39.8581V24.409H181.643C182.996 24.409 184.151 24.6223 185.108 25.049C186.064 25.4757 186.8 26.0937 187.315 26.9029C187.83 27.7121 188.087 28.6759 188.087 29.7941C188.087 30.9123 187.83 31.8761 187.315 32.6853C186.8 33.4798 186.064 34.0904 185.108 34.5171C184.151 34.9438 182.996 35.1571 181.643 35.1571H176.831L177.825 34.1419V39.8581H175.617ZM185.946 39.8581L182.018 34.2523H184.379L188.352 39.8581H185.946ZM177.825 34.3626L176.831 33.2812H181.576C182.989 33.2812 184.056 32.9796 184.777 32.3763C185.512 31.7583 185.88 30.8976 185.88 29.7941C185.88 28.6906 185.512 27.8372 184.777 27.234C184.056 26.6307 182.989 26.3291 181.576 26.3291H176.831L177.825 25.2256V34.3626Z"
        fill="#05B6B2"
      />
      <path d="M191.653 39.8581V24.409H193.86V39.8581H191.653Z" fill="#05B6B2" />
      <path
        d="M205.393 40.0347C204.216 40.0347 203.127 39.8434 202.127 39.4608C201.141 39.0636 200.28 38.5118 199.544 37.8056C198.824 37.0846 198.257 36.2459 197.845 35.2896C197.433 34.3332 197.227 33.2812 197.227 32.1335C197.227 30.9859 197.433 29.9339 197.845 28.9775C198.257 28.0211 198.831 27.1898 199.567 26.4836C200.302 25.7626 201.163 25.2109 202.149 24.8283C203.149 24.4311 204.238 24.2324 205.415 24.2324C206.607 24.2324 207.703 24.4384 208.704 24.8504C209.719 25.2476 210.58 25.8435 211.286 26.6381L209.851 28.0285C209.263 27.4105 208.601 26.9544 207.865 26.6601C207.129 26.3512 206.342 26.1967 205.503 26.1967C204.635 26.1967 203.826 26.3438 203.076 26.6381C202.34 26.9323 201.7 27.3443 201.156 27.874C200.611 28.4037 200.184 29.0364 199.876 29.772C199.581 30.493 199.434 31.2802 199.434 32.1335C199.434 32.9869 199.581 33.7814 199.876 34.5171C200.184 35.2381 200.611 35.8634 201.156 36.3931C201.7 36.9228 202.34 37.3347 203.076 37.629C203.826 37.9233 204.635 38.0704 205.503 38.0704C206.342 38.0704 207.129 37.9233 207.865 37.629C208.601 37.32 209.263 36.8492 209.851 36.2165L211.286 37.6069C210.58 38.4015 209.719 39.0047 208.704 39.4167C207.703 39.8287 206.6 40.0347 205.393 40.0347Z"
        fill="#05B6B2"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_17531_63858"
        x1="25.0317"
        y1="3.42578"
        x2="25.0317"
        y2="62.4177"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#04CC90" />
        <stop offset="1" stop-color="#049ACC" />
      </linearGradient>
      <clipPath id="clip0_17531_63858">
        <rect width="211.286" height="67.9375" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
