import React, { useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import { createCommon, palette, scrollbar } from './palette/palette';
import typography from './typography';
import shadows from './shadows';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import GlobalStyles from './globalStyles';
import { useThemeType } from 'src/contexts/themeTypeContext/theme-type-context';

declare module '@mui/material/styles' {
  interface Theme {
    scrollbar: {
      thumb: string;
      thumbHover: string;
      background: string;
    };
    sidebar: {
      background: string;
      unselectedText: string;
      selectedSiteBackground: string;
      selectedNavItemBackground: string;
      alarmNotificationColor: string;
    };
  }
  interface ThemeOptions {
    scrollbar?: {
      thumb?: string;
      thumbHover?: string;
      background?: string;
    };
    sidebar: {
      background: string;
      unselectedText: string;
      selectedSiteBackground: string;
      selectedNavItemBackground: string;
      alarmNotificationColor: string;
    };
  }
}

type Props = {
  children: React.ReactNode;
};
export default function ThemeProvider({ children }: Props) {
  const { customerTheme, themeType, customerThemeLoaded } = useThemeType();

  const customSidebar = useMemo(
    () => ({
      background: customerTheme.sidebar.background,
      unselectedText: customerTheme.sidebar.unselectedText,
      selectedSiteBackground: customerTheme.sidebar.selectedSiteBackground,
      selectedNavItemBackground: customerTheme.sidebar.selectedNavItemBackground,
      alarmNotificationColor: customerTheme.sidebar.alarmNotificationColor,
    }),
    [customerTheme.sidebar]
  );

  const CURRENT_COMMON = createCommon(customerTheme.primary);

  const themeColors = useMemo(
    () => palette(themeType, CURRENT_COMMON),
    [themeType, CURRENT_COMMON]
  );

  const themeOptions = useMemo<ThemeOptions>(
    () => ({
      palette: themeColors,
      typography,
      shape: { borderRadius: 8 },
      direction: 'ltr',
      shadows: shadows(themeType),
      customShadows: customShadows(themeType, themeColors),
      scrollbar: scrollbar(themeType),
      sidebar: customSidebar,
    }),
    [themeColors, themeType, customSidebar]
  );

  const theme = useMemo(() => {
    if (customerThemeLoaded) {
      const createdTheme = createTheme(themeOptions);
      createdTheme.components = componentsOverride(createdTheme);
      return createdTheme;
    }
  }, [themeOptions, customerThemeLoaded]);

  if (!theme) return <></>;

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
