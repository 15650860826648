import { useRef, useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { CloseIcon, SimpleCheckmarkIcon } from 'src/assets';
import { iconBox, inputField, StyledEditTitleBox, spanFiledStyle } from './style';

type Props = {
  value: string;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  iconSize: number;
  greenColor: string;
  onCloseEditMode: () => void;
  toggleEditHandler: () => void;
};

export const EditTitleBox = ({
  value,
  onChangeHandler,
  iconSize,
  greenColor,
  onCloseEditMode,
  toggleEditHandler,
}: Props) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const [inputWidth, setInputWidth] = useState(0);

  useEffect(() => {
    if (spanRef.current) {
      setInputWidth(spanRef.current.offsetWidth);
    }
  }, [value]);
  return (
    <StyledEditTitleBox>
      <TextField
        sx={inputField}
        value={value}
        onChange={onChangeHandler}
        // onBlur={onCloseEditMode}
        autoFocus
        variant="standard"
        inputProps={{
          style: {
            width: `${inputWidth}px`,
            boxSizing: 'border-box',
          },
          'data-testid': 'edit-title-input-field',
        }}
        InputProps={{
          disableUnderline: true,
          sx: {
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: 'none',
            },
          },
        }}
      />
      <Box component="span" ref={spanRef} sx={spanFiledStyle}>
        {value}
      </Box>
      <Box sx={iconBox} onClick={onCloseEditMode}>
        <CloseIcon width={18} height={12} />
      </Box>
      <Box sx={iconBox} onClick={toggleEditHandler}>
        <SimpleCheckmarkIcon width={iconSize} height={iconSize} fill={greenColor} />
      </Box>
    </StyledEditTitleBox>
  );
};
