import { useState, useEffect } from 'react';
import { AssetType } from '../types/types';
import { useWebSockets } from './useWebSockets';
import { STATE } from '../config';
import { getIndexOfLastElement } from '../utils';

type usePumpDataArgumentsTypes = {
  asset: AssetType;
};

export const useAssetStateData = ({ asset }: usePumpDataArgumentsTypes) => {
  const [assetState, setAssetState] = useState<boolean | null>(false);

  const assetAssignedTag = asset?.assignedTags?.find((tag) => tag?.tagName.toLowerCase() === STATE);

  const { messages } = useWebSockets(assetAssignedTag?.assignedGatewayId!);
  const indexOfLastElement = getIndexOfLastElement(messages);

  const dataSocket = messages[indexOfLastElement];
  const gatewayInputs = dataSocket?.payload.data.onCreatedReading.gateway_inputs;
  const findInput = gatewayInputs?.find(
    (input: { id: string | undefined }) => input.id === assetAssignedTag?.assignedTagId
  );

  useEffect(() => {
    // eslint-disable-next-line
    if (assetAssignedTag?.assignedGatewayId) setAssetState(findInput?.value == 1 ? true : false);
  }, [assetAssignedTag?.assignedGatewayId, findInput?.value]);

  return { assetState };
};
