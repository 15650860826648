import { SelectProps } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { useAssetPropertyData } from 'src/features/reports/context/assetPropertyContext';
import { getAvailableAggregationOptions } from './utils/getAvailableAggregationProperties';
import { AGGREGATION_SELECT_OPTIONS } from 'src/features/reports/config/config';
import AggregationSelect from '../../sidebar/add-column-inputs/AggregationSelect';

const AssetAggregationSelect: FunctionComponent<SelectProps> = (props) => {
  const [aggregationOptions, setAggregationOptions] = useState(
    AGGREGATION_SELECT_OPTIONS.map((option) => ({ ...option, available: true }))
  );
  const assetPropertyData = useAssetPropertyData();

  useEffect(() => {
    setAggregationOptions((prevAggregations) =>
      getAvailableAggregationOptions(prevAggregations, assetPropertyData)
    );
  }, [assetPropertyData]);
  return <AggregationSelect options={aggregationOptions} {...props} />;
};

export default AssetAggregationSelect;
