import { useLocation, useNavigate } from 'react-router';

const useQueryParamsActions = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(search);

  const _navigateWithNewParams = () =>
    navigate({
      search: searchParams.toString(),
    });

  const append = (key: string, value: string) => {
    searchParams.set(key, value);
    _navigateWithNewParams();
  };

  const remove = (key: string) => {
    searchParams.delete(key);
    _navigateWithNewParams();
  };

  const get = (key: string) => searchParams.get(key);

  return { append, get, remove };
};

export default useQueryParamsActions;
