import { SyntheticEvent } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  StyledDrawer,
  threeBox,
  titleBox,
  titleStyle,
  titleHeaderBox,
  StledBtnsBox,
  searchFiled,
} from './style';
import { FilterCategoryItem } from '../filter-category-item';
import { FilterTreeCategoryList } from '../filter-tree-category-list';
import { GetAllResponse } from 'src/services/sites';
import { ASSET_REPORTS_FILTER_CATEGORIES } from '../../config/config';

type Props = {
  isOpen: boolean;
  selectedItems: string[];
  handleSelectedItemsChange: (event: SyntheticEvent, ids: string[]) => void;
  sites: { value: GetAllResponse[] | null; loaded: boolean };
  toggleSidebar: (state: boolean) => void;
};

export const SideBarFilter = ({
  isOpen,
  selectedItems,
  sites,
  handleSelectedItemsChange,
  toggleSidebar,
}: Props) => (
  <StyledDrawer
    anchor="right"
    open={isOpen}
    onClose={(event, reason) => {
      toggleSidebar(false);
    }}
  >
    <Box>
      <Box sx={titleHeaderBox}>
        <Typography variant="h5">Filters</Typography>
      </Box>
      {ASSET_REPORTS_FILTER_CATEGORIES.map((categoryItem) => (
        <FilterCategoryItem
          key={categoryItem.type}
          title={categoryItem.type}
          options={categoryItem.options}
        />
      ))}

      <Box>
        <Box sx={titleBox}>
          <Typography sx={titleStyle}>Sites / Assets</Typography>
        </Box>
        <Box sx={threeBox}>
          <TextField sx={searchFiled} size="small" placeholder="Search Site or Asset" />
          <FilterTreeCategoryList
            selectedItems={selectedItems}
            handleSelectedItemsChange={handleSelectedItemsChange}
            sites={sites}
          />
        </Box>
      </Box>

      <StledBtnsBox>
        <Button
          sx={{ flex: 1 }}
          variant="outlined"
          color="error"
          onClick={() => toggleSidebar(false)}
        >
          Clear All
        </Button>
        <LoadingButton sx={{ flex: 1 }} type="submit" variant="contained" color="primary">
          Apply
        </LoadingButton>
      </StledBtnsBox>
    </Box>
  </StyledDrawer>
);
