import { Button, Divider, Stack, Typography, Box, useTheme } from '@mui/material';
import { UserIcon } from 'src/assets';
import { MenuPopover, MenuPopoverItem } from 'src/components';
import { ACCESS_ROLES } from 'src/config';
import { useThemeType } from 'src/contexts/themeTypeContext/theme-type-context';
import { useAuthentication } from 'src/features/authentication/context';
import { useHtmlElement, useToggle } from 'src/hooks';
import { iconBox, IconWrapper, menuPopoverItemStyle, Overlay } from './style';
import featureFlags from '../../../feature-flags.json';
import { Link } from 'react-router-dom';

type Props = {
  isTransparent: boolean;
};

export const UserPopoverBtn = ({ isTransparent }: Props) => {
  const { state, onToggle } = useToggle();
  const theme = useTheme();
  const { htmlElement, addHtmlElement, removeHtmlElement } = useHtmlElement();
  const { themeType, toggleThemeType } = useThemeType();
  const { user, logout } = useAuthentication();

  const greyColor = theme.palette.grey[700];
  const greyLightColor = theme.palette.grey[300];
  const whiteColor = theme.palette.common.white;
  const textDisabledColor = theme.palette.text.disabled;

  const { customerId, getCurrentRole } = useAuthentication();
  const isSuperAdmin = getCurrentRole(null, null) === ACCESS_ROLES.SUPER_ADMIN;

  const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    addHtmlElement(event.currentTarget);
    onToggle();
  };

  const handleMenuClose = () => {
    removeHtmlElement();
    onToggle();
  };

  const handleProfileClick = () => {};

  const handleLogoutClick = () => {
    logout();
  };

  const handleThemeSwitch = () => {
    toggleThemeType();
  };

  return (
    <Box sx={iconBox}>
      <IconWrapper onClick={(event) => handleMenuOpen(event)} data-sm={'profile-icon'}>
        <Overlay active={state.toString()} />
        <UserIcon fill={greyColor} outline={isTransparent ? whiteColor : greyLightColor} />
      </IconWrapper>
      <MenuPopover open={htmlElement} onClose={handleMenuClose} arrow="top-right">
        <MenuPopoverItem
          sx={menuPopoverItemStyle}
          customContent={
            <Stack>
              <Typography variant="subtitle1">{user?.firstName + ' ' + user?.lastName}</Typography>
              <Typography variant="body2" color={textDisabledColor}>
                {user?.email}
              </Typography>
            </Stack>
          }
          handleClick={handleProfileClick}
          testId="profile-button"
        />
        {featureFlags.userProfile === 1 && !isSuperAdmin ? (
          <Link to={`/profile?customerId=${customerId.value}`}>
            <MenuPopoverItem
              sx={{ px: '15px!important', py: '6px!important' }}
              customContent={
                <Button
                  sx={{
                    width: '100%',
                    color: theme.palette.common.black,
                    fontSize: '16px',
                    fontWeight: theme.typography.fontWeightRegular,
                    padding: '0',
                    '&:hover': {
                      background: 'none',
                    },
                  }}
                  variant="text"
                  data-sm="add-site-button"
                >
                  Profile
                </Button>
              }
              handleClick={() => {}}
              testId="profile-button-cta"
            />
          </Link>
        ) : null}
        <Divider />
        {featureFlags.themeMode || featureFlags.userIds.includes(user?.userId) ? (
          <MenuPopoverItem
            sx={{ px: '20px!important', py: '8px!important' }}
            customContent={
              <Typography>Switch to {themeType === 'dark' ? 'light mode' : 'dark mode'}</Typography>
            }
            handleClick={handleThemeSwitch}
            testId="toggle-theme-button"
          />
        ) : null}
        <MenuPopoverItem
          sx={{ px: '20px!important', py: '8px!important' }}
          customContent={<Typography>Logout</Typography>}
          handleClick={handleLogoutClick}
          testId="logout-button"
        />
      </MenuPopover>
    </Box>
  );
};
