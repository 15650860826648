import { Button, Stack, TextField, Typography, useTheme } from '@mui/material';
import { StyledReportsHeader, btnsBox, filedsBox, inputFiled } from './style';
import { FunctionComponent, useEffect, useState } from 'react';
import { useResponsive } from 'src/hooks';
import { useAuthentication } from 'src/features/authentication/context';
import timezones from '../../data/timezones.json';
import { useFormContext } from 'react-hook-form';
import saveReportTemplate from '../../utils/saveReport';
import { useSnackbar } from 'notistack';
import { RHFAutocomplete } from 'src/components/minimals/form';
import { ApiResourceState } from '../../types';
import { columnsPopulated } from '../../utils/columnsPopulated';

export const ReportsHeader: FunctionComponent<{ title: string }> = ({ title }) => {
  const theme = useTheme();
  const isTablet = useResponsive('down', 'lg');
  const { enqueueSnackbar } = useSnackbar();
  const [reportState, setReportState] = useState<{
    status: ApiResourceState;
    error: Error | null;
    data: any;
  }>({
    status: 'INITIAL',
    error: null,
    data: null,
  });
  const {
    customerId: { value: customerId },
  } = useAuthentication();
  const { setValue, getValues, watch } = useFormContext();

  const reportType = watch('header.reportType');

  useEffect(() => {
    const isData = window.location.pathname.includes('/build-report/data');
    if (!reportType) {
      if (isData) {
        setValue('header.reportType', { id: 'DATA', label: 'Data Report' });
      } else {
        setValue('header.reportType', { id: 'ASSET', label: 'Asset Report' });
      }
    }
  }, [setValue, reportType]);

  const onSaveHandler = async () => {
    const data = getValues();

    setReportState((prevState) => ({ ...prevState, status: 'LOADING' }));

    try {
      const report = await saveReportTemplate(
        data.header.reportType?.id,
        { ...data, title },
        customerId!
      );
      setReportState((prevState) => ({ ...prevState, data: report, status: 'SUCCESS' }));
      enqueueSnackbar('Report template successfully saved!', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Could not save report template!\n' + err.message, { variant: 'error' });
      setReportState((prevState) => ({ ...prevState, error: err, status: 'ERROR' }));
    }
  };

  return (
    <StyledReportsHeader theme={theme} isTablet={isTablet}>
      <Stack flexDirection="column" sx={{ ...filedsBox, alignItems: isTablet ? 'center' : '' }}>
        <Typography fontWeight="700" variant="body1">
          {watch('header.reportType')?.label || 'Loading...'}
        </Typography>
        <RHFAutocomplete
          sx={{ ...inputFiled, width: isTablet ? '100%' : '280px' }}
          name="header.timeZone"
          options={timezones}
          disableClearable
          size="small"
          getOptionLabel={(option) =>
            typeof option === 'string'
              ? timezones.find((el) => el.id === option)?.label || ''
              : option.label
          }
          renderInput={(params) => <TextField {...params} fullWidth label={'Time Zone'} />}
        />
      </Stack>

      <Stack flexDirection="row" justifyContent="center" sx={btnsBox}>
        <Button size="medium" variant="outlined">
          Undo Changes
        </Button>
        <Button
          disabled={reportState.status === 'LOADING' || !columnsPopulated(watch()?.data)}
          size="medium"
          variant="contained"
          onClick={onSaveHandler}
        >
          {reportState.status === 'LOADING' ? 'Saving template...' : 'Save Template'}
        </Button>
      </Stack>
    </StyledReportsHeader>
  );
};
