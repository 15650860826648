import { useState, SyntheticEvent } from 'react';

export const useFilters = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const [slecetedAssetsAndSitesTypes] = useState({
    assetTypes: [],
    sitesTypes: [],
  });

  const toggleSidebar = (newOpen: boolean) => {
    setIsOpen(newOpen);
  };

  const handleSelectedItemsChange = (event: SyntheticEvent, ids: string[]) => {
    setSelectedItems(ids);
  };

  return {
    isOpen,
    selectedItems,
    handleSelectedItemsChange,
    toggleSidebar,
    slecetedAssetsAndSitesTypes,
  };
};
