import { FunctionComponent } from 'react';
import SectionTitle from './SectionTitle';
import { Field, ICustomFieldBaseProps } from '../types';
import { BlockStack, CustomTextArea } from '../styles';
import { useFormContext } from 'react-hook-form';

const NotesField: FunctionComponent<ICustomFieldBaseProps<Field>> = ({ field }) => {
  const { setValue, watch } = useFormContext();
  return (
    <BlockStack>
      <SectionTitle>Notes</SectionTitle>
      <CustomTextArea
        onChange={(event) => setValue(field.id, event.target.value.slice(0, 2000))}
        maxRows={2}
        value={watch(field.id)}
        minRows={2}
      />
    </BlockStack>
  );
};

export default NotesField;
