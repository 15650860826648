import { Button, Stack } from '@mui/material';
import { FunctionComponent } from 'react';

interface IFormActionsProps {
  onCancel: () => void;
  isEdit: boolean;
}

const FormActions: FunctionComponent<IFormActionsProps> = ({ onCancel, isEdit = false }) => (
  <Stack justifyContent="space-between" flexDirection="row">
    <Stack>
      {isEdit && (
        <Button variant="contained" color="error">
          Delete
        </Button>
      )}
    </Stack>
    <Stack flexDirection="row" gap="12px">
      <Button onClick={onCancel} variant="outlined">
        Cancel
      </Button>
      <Button type="submit" variant="contained">
        Save
      </Button>
    </Stack>
  </Stack>
);

export default FormActions;
